<template>
    <OverlayPanel ref="op" showCloseIcon style="width: 900px; max-width: 95%">
        <div v-if="!loading" class="grid max-h-30rem md:max-h-full overflow-y-auto overflow-x-hidden">
            <section class="flex flex-column col-12 md:col-7 gap-1">
                <span class="text-2xl font-bold">{{ prestador.name }}</span>
                <span class="text-2xl font-bold flex align-items-center gap-3"
                    ><i class="pi pi-phone text-2xl font-bold" /> {{ prestador.commercialPhone }}</span
                >
                <span class="font-semibold text-lg">{{ prestador.neighborhood }}, {{ prestador.city.cidade }} - {{ prestador.city.estado }}</span>
                <span class="font-semibold text-lg">{{ prestador.email }}</span>
                <span class="font-semibold text-lg">{{ prestador.street }}, {{ prestador.number }}</span>
            </section>
            <section class="flex flex-column col-12 md:col-5">
                <span class="font-bold h-2rem flex align-items-center text-lg">Tipo de Atendimento: {{ tipoAtendimento }}</span>
                <span v-if="showDadosBancarios" class="border-1 border-round border-300 flex flex-column text-lg font-semibold p-2 mt-3 bg-gray-100">
                    <span class="w-full text-center font-bold">Dados Bancários:</span>
                    <span v-if="prestador.nomeBanco">Banco: {{ prestador.nomeBanco }}({{ prestador.codigoBanco }})</span>
                    <span v-if="prestador.codigoAgencia">Agência: {{ prestador.codigoAgencia }}</span>
                    <span v-if="prestador.contaCorrente">Conta Corrente: {{ prestador.contaCorrente }}</span>
                    <span v-if="prestador.chavePix">Chave Pix: {{ prestador.chavePix }}</span>
                    <span v-if="prestador.cnpjTitularConta">CNPJ: {{ prestador.cnpjTitularConta }}</span>
                </span>
            </section>

            <div class="w-full overflow-x-auto">
                <div class="grid pt-4 pb-3 pl-5 w-full m-0" style="min-width: 590px; max-width: 100vw">
                    <div
                        v-for="linha in dadosEmFormatoTabela"
                        :key="linha.label"
                        class="col-12 w-full grid border-bottom-2 border-100 px-0 pb-0 overflow-x-auto"
                    >
                        <span class="col align-self-center text-xl" :class="linha.labelClass">{{ linha.label }}</span>
                        <span
                            v-for="(item, index) in linha.items"
                            :key="`${item}-${index}`"
                            class="col align-self-center text-center text-xl"
                            :class="`${linha.itemClass} ${classeTipoHorario(item)}`"
                            >{{ item }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </OverlayPanel>
</template>

<script setup>
import { ref, defineExpose, computed } from 'vue';
import BaseService from '@/services/BaseService';

const prestador = ref(null);
const op = ref(null);
const loading = ref(false);
const $service = new BaseService('/suppliers');
const showDadosBancarios = ref(false);

async function obterDadosPrestador(prestadorId) {
    loading.value = true;
    const { data } = await $service.findById(prestadorId);
    prestador.value = data;
    loading.value = false;
}

function renderTipoHorario(tipo) {
    const map = {
        ORDEM_CHEGADA: 'Ordem de chegada',
        HORARIO_MARCADO: 'Horário marcado'
    };
    return map[tipo] || 'Inconsistente';
}

function classeTipoHorario(labelTipo) {
    if (labelTipo === 'Ordem de chegada') return 'bg-blue-100 text-blue-900';
    if (labelTipo === 'Horário marcado') return 'bg-orange-100 text-orange-900';
}

const tipoAtendimento = computed(() => {
    if (prestador.value.openingHours.every((item) => item.serviceTypeAm === 'ORDEM_CHEGADA' && item.serviceTypePm === 'ORDEM_CHEGADA')) {
        return 'Ordem de chegada';
    }
    if (prestador.value.openingHours.every((item) => item.serviceTypeAm === 'HORARIO_MARCADO' && item.serviceTypePm === 'HORARIO_MARCADO')) {
        return 'Horário marcado';
    }
    if (
        prestador.value.openingHours.some(
            (item) =>
                (item.serviceTypeAm === 'HORARIO_MARCADO' || item.serviceTypeAm === 'ORDEM_CHEGADA') &&
                (item.serviceTypePm === 'ORDEM_CHEGADA' || item.serviceTypePm === 'HORARIO_MARCADO')
        )
    ) {
        return 'Ambos';
    }
    return 'Inconsistente';
});

// TODO: Terminar função(verificar caso em que tenha período sem horário)
const dadosEmFormatoTabela = computed(() => {
    if (!prestador.value) return [];
    return [
        {
            label: 'Período',
            labelClass: 'font-semibold bg-gray-200',
            items: prestador.value.openingHours.map((item) => item.weekDay),
            itemClass: 'uppercase font-semibold bg-gray-200'
        },
        {
            label: 'Manhã',
            labelClass: 'font-bold',
            items: prestador.value.openingHours.map((item) => renderTipoHorario(item.serviceTypeAm)),
            itemClass: 'uppercase font-bold m-2 p-1'
        },
        {
            label: 'Início manhã',
            labelClass: 'font-semibold',
            items: prestador.value.openingHours.map((item) => item.startHourAm),
            itemClass: 'font-medium'
        },
        {
            label: 'Final manhã',
            labelClass: 'font-semibold',
            items: prestador.value.openingHours.map((item) => item.endHourAm),
            itemClass: 'font-medium'
        },
        {
            label: 'Tarde',
            labelClass: 'font-bold',
            items: prestador.value.openingHours.map((item) => renderTipoHorario(item.serviceTypePm)),
            itemClass: 'uppercase font-bold m-2 p-1'
        },
        {
            label: 'Início tarde',
            labelClass: 'font-semibold',
            items: prestador.value.openingHours.map((item) => item.startHourPm),
            itemClass: 'font-medium'
        },
        {
            label: 'Final tarde',
            labelClass: 'font-semibold',
            items: prestador.value.openingHours.map((item) => item.endHourPm),
            itemClass: 'font-medium'
        }
    ];
});

async function open(event, prestadorId, showInfoBancarias = false) {
    showDadosBancarios.value = showInfoBancarias;
    op?.value?.show(event);
    await obterDadosPrestador(prestadorId);
}

defineExpose({
    open
});
</script>
