import { createRouter, createWebHashHistory, RouterView } from 'vue-router';
import gestaoMedica from './routes/gestao_medica';
import gestaoMedicaV3 from './pages/gestao-medica-v3/routes/gestao-medica-v3';
import autoagendamento from './pages/autoagendamento/routes';

const Painel = () => import('@/pages/painel/Index.vue');

const EmpresasList = () => import('@/pages/empresas/Index.vue');
const EmpresasForm = () => import('@/pages/empresas/Form.vue');

const ImportacaoList = () => import('@/pages/importacao/Index.vue');
const ImportacaoForm = () => import('@/pages/importacao/Form.vue');

const ImportacaoCadastroEstruturaList = () => import('@/pages/importacao-cadastro-estrutura/Index.vue');
const ImportacaoCadastroEstruturaForm = () => import('@/pages/importacao-cadastro-estrutura/Form.vue');

const ImportacaoClienteList = () => import('@/pages/importacao-cliente/Index.vue');
const ImportacaoClienteForm = () => import('@/pages/importacao-cliente/Form.vue');

const ImportacaoSeniorList = () => import('@/pages/integracao-senior/Index.vue');
const ImportacaoSeniorForm = () => import('@/pages/integracao-senior/Form.vue');

const ImportacaoApollusList = () => import('@/pages/integracao-apollus/Index.vue');
const ImportacaoApollusForm = () => import('@/pages/integracao-apollus/Form.vue');

const UsuariosList = () => import('@/pages/usuarios/Index.vue');
const UsuariosForm = () => import('@/pages/usuarios/Form.vue');

const ClientesList = () => import('@/pages/clientes/Index.vue');
const ClientesForm = () => import('@/pages/clientes/Form.vue');

const EPCIndex = () => import('@/pages/EPC/Index.vue');
const EPCForm = () => import('@/pages/EPC/Form.vue');

const PerfilExposicaoIndex = () => import('@/pages/perfil-exposicao/Index.vue');
const PerfilExposicaoForm = () => import('@/pages/perfil-exposicao/Form.vue');

const PreAdmissao = () => import('@/pages/gestao-medica/components/pre-admissao/PreAdmissao.vue');

const ProfissionaisSesmtIndex = () => import('@/pages/profissionais-sesmt/Index.vue');
const ProfissionaisSesmtForm = () => import('@/pages/profissionais-sesmt/Form.vue');

const MedidaAdmEngenhariaIndex = () => import('@/pages/medida-adm-engenharia/Index.vue');
const MedidaAdmEngenhariaForm = () => import('@/pages/medida-adm-engenharia/Form.vue');

const EPIIndex = () => import('@/pages/EPI/Index.vue');
const EPIForm = () => import('@/pages/EPI/Form.vue');

const MatrizConfigIndex = () => import('@/pages/matriz-config/Index.vue');
const MatrizConfigForm = () => import('@/pages/matriz-config/Form.vue');

const FonteGeradoraIndex = () => import('@/pages/fonte-geradora/Index.vue');
const FonteGeradoraForm = () => import('@/pages/fonte-geradora/Form.vue');

const CadastroColaborador = () => import('@/pages/cadastros/cadastroColaborador.vue');
const LiberarPrestador = () => import('@/pages/prestador-cliente/Index.vue');
const AtualizarPrestador = () => import('@/pages/atualizar-prestador/Index.vue');
const AtualizarCadastroPrestador = () => import('@/pages/atualizar-prestador/components/AtualizarCadastroPrestador.vue');
const PainelAgendamentoIndex = () => import('@/pages/painel-agendamento/Index.vue');
const PainelAtendimentoIndex = () => import('@/pages/painel-atendimento/Index.vue');
const ReservaHorarioIndex = () => import('@/pages/reserva-horario/Index.vue');
const ReservaHorarioDia = () => import('@/pages/reserva-horario/Dia.vue');

// const ClientesRegrasForm = () => import('@/pages/clientes/regras/Form.vue')
const SetorList = () => import('@/pages/setores/Index.vue');
const SetorForm = () => import('@/pages/setores/Form.vue');

const CargoList = () => import('@/pages/cargos/Index.vue');
const CargoForm = () => import('@/pages/cargos/Form.vue');

const HierarquiaList = () => import('@/pages/hierarquias/Index.vue');
const HierarquiaForm = () => import('@/pages/hierarquias/Form.vue');

const UnidadeList = () => import('@/pages/unidades/Index.vue');
const UnidadeForm = () => import('@/pages/unidades/Form.vue');

const FuncionarioList = () => import('@/pages/funcionarios/Index.vue');
const FuncionarioForm = () => import('@/pages/funcionarios/Form.vue');

const ExameList = () => import('@/pages/exames/Index.vue');
const ExameForm = () => import('@/pages/exames/Form.vue');

const ProfileList = () => import('@/pages/perfis/Index.vue');
const ProfileForm = () => import('@/pages/perfis/Form.vue');

const MedicoList = () => import('@/pages/medicos/Index.vue');
const MedicoForm = () => import('@/pages/medicos/Form.vue');

const ProfissionaisList = () => import('@/pages/profissionais/Index.vue');
const ProfissionaisForm = () => import('@/pages/profissionais/Form.vue');

const RoutesList = () => import('@/pages/routes/Index.vue');
const RoutesForm = () => import('@/pages/routes/Form.vue');

const ProdutosServicosList = () => import('@/pages/produtos-servicos/Index.vue');
const ProdutosServicosForm = () => import('@/pages/produtos-servicos/Form.vue');
const RelatorioLevantamento = () => import('@/pages/inventario-riscos/components/relatorio-levantamento/Index.vue');

const InventarioRiscoList = () => import('@/pages/seguranca/inventarioRisco/Index.vue');
const InventarioRiscoList2 = () => import('@/pages/inventario-riscos/Index.vue');
const LiberaHierarquiaList = () => import('@/pages/seguranca/liberaHierarquia/Index.vue');

const VehiclesList = () => import('@/pages/vehicles/Index.vue');
const SchedulingVehiclesList = () => import('@/pages/vehicles/scheduling/Index.vue');

const ProfessionalsList = () => import('@/pages/professionals/Index.vue');
// const ProfessionalsForm = () => import('@/pages/professionals/Form.vue')
// const ProfessionalsList = () => import('@/pages/professionals/Index.vue')
const CompetenciasIndex = () => import('@/pages/competencias/Index.vue');
const CompetenciasForm = () => import('@/pages/competencias/Form.vue');

const DescricaoRiscoIndex = () => import('@/pages/descricao-risco/Index.vue');
const DescricaoRiscoForm = () => import('@/pages/descricao-risco/Form.vue');

const EquipmentList = () => import('@/pages/equipment/Index.vue');
const SchedulingList = () => import('@/pages/equipment/agendamento/Index.vue');
const PrestadorList = () => import('@/pages/prestadores/Index.vue');
const PrestadorForm = () => import('@/pages/prestadores/Form.vue');

const PrestadorProcedimentoList = () => import('@/pages/prestador-procedimento/Index.vue');

const PrestadorProcedimentoClienteList = () => import('@/pages/prestador-procedimento-cliente/Index.vue');
const HorarioDeAtendimentoList = () => import('@/pages/prestadores/components/horario-de-atendimento/HorarioDeAtendimento.vue');

const NaoConformidadeList = () => import('@/pages/nao-conformidade/Index.vue');
const NaoConformidadeForm = () => import('@/pages/nao-conformidade/Form.vue');

const RiscoList = () => import('@/pages/riscos/Index.vue');
const RiscoForm = () => import('@/pages/riscos/Form.vue');

const RiskProcedureList = () => import('@/pages/riscos-exames/Index.vue');
const RiskProcedureForm = () => import('@/pages/riscos-exames/Form.vue');

const ResponsibleList = () => import('@/pages/responsaveis/Index.vue');
const ResponsibleForm = () => import('@/pages/responsaveis/Form.vue');

const Profile = () => import('@/pages/Profile.vue');

const QuestionarioTelemedicina = () => import('@/pages/questionario-telemedicina/Index.vue');
const QuestionarioTelemedicinaForm = () => import('@/pages/questionario-telemedicina/Form.vue');

const AgendamentoAtendimento = () => import('@/pages/agendamento-atendimento/Index.vue');
const TelemedicinaPaciente = () => import('@/pages/telemedicina-paciente/Index.vue');
const TelemedicinaQuestionarioPaciente = () => import('@/pages/telemedicina-paciente/Questionario.vue');

const GrupoList = () => import('@/pages/grupos/Index.vue');
const GrupoForm = () => import('@/pages/grupos/Form.vue');

const CompaniesExportaDadosList = () => import('@/pages/companies-exporta-dados/Index.vue');
const CompaniesExportaDadosForm = () => import('@/pages/companies-exporta-dados/Form.vue');

const BoletimIndex = () => import('@/pages/boletins/Index.vue');
const BoletimForm = () => import('@/pages/boletins/Form.vue');
const BoletimList = () => import('@/pages/boletins/List.vue');

const EsocialDashboard = () => import('@/pages/esocial/dashboard/Index.vue');
const EsocialList = () => import('@/pages/esocial/eventos/Index.vue');
const EsocialForm = () => import('@/pages/esocial/eventos/Form.vue');

const FeriadosIndex = () => import('@/pages/feriados/Index.vue');

const TelaChamadaProfissisonal = () => import('@/pages/tela-chamada-profissional/List.vue');
const TelaChamadaAdministrativa = () => import('@/pages/tela-chamada-administrativa/List.vue');

const ConvocacaoExamesList = () => import('@/pages/convocacao-exames/Index.vue');
const ConvocacaoExamesForm = () => import('@/pages/convocacao-exames/Form.vue');
const PerguntaList = () => import('@/pages/perguntas/Index.vue');
const PerguntaForm = () => import('@/pages/perguntas/Form.vue');

const PerguntaSESMTList = () => import('@/pages/perguntas-sesmt/Index.vue');
const PerguntaSESMTForm = () => import('@/pages/perguntas-sesmt/Form.vue');

const QuestionarioSESMTList = () => import('@/pages/questionarios-sesmt/Index.vue');
const QuestionarioSESMTForm = () => import('@/pages/questionarios-sesmt/Form.vue');

const QuestionarioList = () => import('@/pages/questionarios/Index.vue');
const QuestionarioForm = () => import('@/pages/questionarios/Form.vue');

const QuestionarioFuncionario = () => import('@/pages/questionarios/QuestionarioFuncionario.vue');
const DownloadAnexoPrestador = () => import('@/pages/download-anexo-prestador/Form.vue');
const GerarQrCode = () => import('@/pages/download-anexo-prestador/FormQrCode.vue');

const LevantamentoRiscoStep = () => import('@/pages/inventario-riscos/components/steps/LevantamentoRiscoStep.vue');
const AepAetStep = () => import('@/pages/inventario-riscos/components/steps/AepAet.vue');
const PgrStep = () => import('@/pages/inventario-riscos/components/steps/Pgr.vue');

const AplicacaoLaudosStep = () => import('@/pages/inventario-riscos/components/steps/AplicacaoLaudos.vue');

const PcmsoStep = () => import('@/pages/inventario-riscos/components/steps/Pcmso.vue');

const RiscoProcedimentoForm = () => import('@/pages/riscos-procedimentos/Form.vue');

const GestaoRiscoIndex = () => import('@/pages/gestao-risco/Index.vue');
const ClientesGroList = () => import('@/pages/gestao-risco/GridClientes.vue');
const UnidadesGroList = () => import('@/pages/gestao-risco/GridUnidades.vue');

const InventarioRiscoAplicacao = () => import('@/pages/inventario-riscos/Form.vue');

const AtividadeEspecificaList = () => import('@/pages/atividade-especifica/Index.vue');
const AtividadeEspecificaForm = () => import('@/pages/atividade-especifica/Form.vue');

const Email = () => import('@/pages/mail/Index.vue');

const OpenIa = () => import('@/pages/open-ia/Index.vue');

const IndicadoresSolicitacaoPorCliente = () => import('@/pages/relatorios-e-indicadores/solicitacao-por-cliente/Index.vue');
const IndicadoresProdutividade = () => import('@/pages/relatorios-e-indicadores/produtividade/Index.vue');
const IndicadoresProdutividadeColetiva = () => import('@/pages/relatorios-e-indicadores/produtividade-coletiva/Index.vue');
const IndicadoresEnvioDeEmail = () => import('@/pages/relatorios-e-indicadores/envio-de-email/Index.vue');
const IndicadoresPrestadores = () => import('@/pages/relatorios-e-indicadores/prestadores/Index.vue');

const DashboardEstrutura = () => import('@/pages/dashboard/estrutura/Index.vue');
const DashboardAgendamentos = () => import('@/pages/dashboard/agendamentos/Index.vue');
const DashboardFaltaConsulta = () => import('@/pages/dashboard/falta-consulta/Index.vue');

const DepositoAntecipado = () => import('@/pages/deposito-antecipado/DepositoAntecipado.vue');
const ImportacaoGupyList = () => import('@/pages/integracao-gupy/Index.vue');

const AgendamentoIndex = () => import('@/pages/gestao_medica/agendamentos/index/Index.vue');
const AgendamentoFormSolicitacao = () => import('@/pages/gestao_medica/agendamentos/form/FormSolicitacao.vue');
const AgendamentoFormPrestador = () => import('@/pages/gestao_medica/agendamentos/form/FormPrestador.vue');
const AgendamentoFormProcedimentos = () => import('@/pages/gestao_medica/agendamentos/form/FormProcedimentos.vue');
const AgendamentoFormSolicitacoes = () => import('@/pages/gestao_medica/agendamentos/form/FormSolicitacoes.vue');
const AgendamentoFormExames = () => import('@/pages/gestao_medica/agendamentos/form/FormExames.vue');
const AgendamentosFormExamesProcedimento = () => import('@/pages/gestao_medica/agendamentos/form/FormExamesProcedimentos.vue');
const ManualSistemaIndex = () => import('@/pages/manual-sistema/Index.vue');
const ManualSistemaForm = () => import('@/pages/manual-sistema/Form.vue');

const routes = [
    {
        path: '/',
        redirect: '/painel',
        name: 'home',
        component: () => import('@/App.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/painel',
                name: 'Painel',
                component: Painel
            },
            {
                path: '/plano-acao',
                name: 'plano-acao',
                redirect: 'plano-acao/listar',
                component: RouterView,
                children: [
                    {
                        path: 'listar',
                        name: 'acao',
                        component: () => import('./pages/plano-acao/Index.vue')
                    },
                    {
                        path: 'criar',
                        name: 'Novo',
                        component: () => import('./pages/plano-acao/Form.vue')
                    },
                    {
                        path: ':id',
                        name: 'Editar',
                        component: () => import('./pages/plano-acao/Form.vue')
                    },
                    {
                        path: 'linha-do-tempo/:id',
                        name: 'Linha do  tempo',
                        component: () => import('./pages/plano-acao/components/linha-do-tempo.vue')
                    },
                    {
                        path: ':id/acompanhamento',
                        name: 'Acompanhamento',
                        component: () => import('./pages/plano-acao/Acompanhamento.vue')
                    },
                    {
                        path: ':id/anexos',
                        name: 'Anexos',
                        component: () => import('./pages/plano-acao/Anexos.vue')
                    },
                    {
                        path: ':id/conclusao',
                        name: 'Conclusao',
                        component: () => import('./pages/plano-acao/Conclusao.vue')
                    }
                ]
            },
            {
                path: '/gestao-risco',
                name: 'Gestão de risco',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'Gestão de riscos - default',
                        redirect: '/gestao-risco/clientes',
                        component: GestaoRiscoIndex,
                        meta: {
                            defaultRoute: true,
                            permission: 'gestaosesmt_gro_cliente:view'
                        }
                    },
                    {
                        path: 'clientes',
                        name: 'Gestão de riscos - Clientes',
                        component: RouterView,
                        meta: {
                            breadcrumbLabel: 'Clientes',
                            permission: 'gestaosesmt_gro_cliente:view'
                        },
                        children: [
                            {
                                path: '',
                                name: 'Gestão de riscos - Clientes - default',
                                component: ClientesGroList,
                                meta: {
                                    defaultRoute: true,
                                    permission: 'gestaosesmt_gro_cliente:view'
                                }
                            },
                            {
                                path: ':id/unidades',
                                name: 'Gestão de riscos - unidades',
                                component: UnidadesGroList,
                                meta: {
                                    breadcrumbLabel: 'Unidades',
                                    permission: 'gestaosesmt_gro_unidade:view'
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'boletins',
                name: 'boletins',
                redirect: '/boletins/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de atualizações',
                        component: BoletimIndex
                    },
                    {
                        path: 'new',
                        name: 'Nova atualizações',
                        component: BoletimForm
                    },
                    {
                        path: ':id',
                        name: 'Editar atualizações',
                        component: BoletimForm
                    },
                    {
                        path: 'view',
                        name: 'Visualizar atualizações',
                        component: BoletimList
                    }
                ]
            },
            {
                path: 'feriados',
                name: 'feriados',
                redirect: '/feriados/index',
                component: RouterView,
                children: [
                    {
                        path: 'index',
                        name: 'Listagem de Feriados',
                        component: FeriadosIndex
                    }
                ]
            },
            {
                path: 'mail',
                name: 'mail',
                redirect: '/mail/index',
                component: RouterView,
                children: [
                    {
                        path: 'index',
                        name: 'Listagem de Emails',
                        component: Email
                    }
                ]
            },
            {
                path: 'open-ia',
                name: 'IA',
                redirect: '/open-ia/index',
                component: RouterView,
                children: [
                    {
                        path: 'index',
                        name: 'IA',
                        component: OpenIa
                    }
                ]
            },
            {
                path: 'atividades-especificas',
                name: 'Atividade Especifica',
                redirect: '/atividades-especificas/index',
                component: RouterView,
                children: [
                    {
                        path: 'index',
                        name: 'Listagem das atividades específicas',
                        component: AtividadeEspecificaList
                    },
                    {
                        path: 'new',
                        name: 'Criar atividades especificas',
                        component: AtividadeEspecificaForm
                    },
                    {
                        path: ':id',
                        name: 'Atualizar atividades específicas',
                        component: AtividadeEspecificaForm
                    }
                ]
            },
            {
                path: 'tela-chamada-profissional',
                name: 'tela-chamada-profissional',
                redirect: '/tela-chamada-profissional/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Tela de Chamada Profissional',
                        component: TelaChamadaProfissisonal,
                        meta: { permission: 'gestamedica_painel_atendimento_listagem:view' }
                    }
                ]
            },
            {
                path: 'tela-chamada-administrativa',
                name: 'tela-chamada-administrativa',
                redirect: '/tela-chamada-administrativa/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Tela de Chamada Administrativa',
                        component: TelaChamadaAdministrativa,
                        meta: { permission: 'gestamedica_painel_atendimento_listagem_admin:view' }
                    }
                ]
            },
            {
                path: 'importacao',
                name: 'importacao',
                redirect: '/importacao/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Importações',
                        component: ImportacaoList
                    },
                    {
                        path: 'new',
                        name: 'Nova importação',
                        component: ImportacaoForm
                    },
                    {
                        path: ':id',
                        name: 'Visualização de importação',
                        component: ImportacaoForm
                    }
                ]
            },
            {
                path: '/importacao-cadastro-estrutura',
                name: 'importacao-cadastro-estrutura',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'Listagem de Importações Cadastro Estrutura',
                        component: ImportacaoCadastroEstruturaList
                    },
                    {
                        path: '/new/:step',
                        name: 'Nova importação cadastro estrutura',
                        component: ImportacaoCadastroEstruturaForm,
                        children: [
                            {
                                path: '/importacao-cadastro-estrutura/new/tipo-importacao',
                                name: 'Tipo Informação',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/TipoImportacao.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/new/selecao-cadastro',
                                name: 'Cadastros',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/SelecaoCadastro.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/new/download-planilha',
                                name: 'Download Planilha',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/DownloadPlanilha.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/new/importacao-validacao',
                                name: 'Importação e Validação',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/ImportacaoValidacao.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/new/conclusao',
                                name: 'Conclusão',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/Conclusao.vue')
                            }
                        ]
                    },
                    {
                        path: ':id/:step',
                        name: 'Editar importação cadastro estrutura',
                        component: ImportacaoCadastroEstruturaForm,
                        children: [
                            {
                                path: '/importacao-cadastro-estrutura/:id/tipo-importacao',
                                name: 'Tipo Informação',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/TipoImportacao.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/:id/selecao-cadastro',
                                name: 'Cadastros',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/SelecaoCadastro.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/:id/download-planilha',
                                name: 'Download Planilha',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/DownloadPlanilha.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/:id/importacao-validacao',
                                name: 'Importação e Validação',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/ImportacaoValidacao.vue')
                            },
                            {
                                path: '/importacao-cadastro-estrutura/:id/conclusao',
                                name: 'Conclusão',
                                component: () => import('@/pages/importacao-cadastro-estrutura/components/Conclusao.vue')
                            }
                        ]
                    }
                ]
            },
            {
                path: 'integracao-senior',
                name: 'integracao-senior',
                redirect: '/integracao-senior/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de integrações Senior',
                        component: ImportacaoSeniorList
                    },
                    {
                        path: ':id',
                        name: 'Visualização de integração Senior',
                        component: ImportacaoSeniorForm
                    }
                ]
            },
            {
                path: 'integracao-apollus',
                name: 'integracao-apollus',
                redirect: '/integracao-apollus/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de integrações Apollo',
                        component: ImportacaoApollusList
                    },
                    {
                        path: 'new',
                        name: 'Nova integração Apollo',
                        component: ImportacaoApollusForm
                    },
                    {
                        path: ':id',
                        name: 'Visualização de integração Apollo',
                        component: ImportacaoApollusForm
                    }
                ]
            },
            {
                path: 'integracao-gupy',
                name: 'integracao-gupy',
                redirect: '/integracao-gupy/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de integrações Gupy',
                        component: ImportacaoGupyList
                    }
                ]
            },
            {
                path: 'companies',
                name: 'empresas',
                redirect: '/companies/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Empresas',
                        meta: { permission: 'configuracoes_empresa:view' },
                        component: EmpresasList
                    },
                    {
                        path: 'new',
                        name: 'Nova Empresa',
                        meta: { permission: 'configuracoes_empresa:criar' },
                        component: EmpresasForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Empresa',
                        meta: { permission: 'configuracoes_empresa:editar' },
                        component: EmpresasForm
                    }
                ]
            },
            {
                path: 'users',
                name: 'usuarios',
                redirect: '/users/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Usuários',
                        meta: { permission: 'configuracoes_usuario:view' },
                        component: UsuariosList
                    },
                    {
                        path: 'new',
                        name: 'Novo Usuário',
                        meta: { permission: 'configuracoes_usuario:criar' },
                        component: UsuariosForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Usuário',
                        meta: { permission: 'configuracoes_usuario:editar' },
                        component: UsuariosForm
                    }
                ]
            },
            {
                path: '/gestao-medica',
                redirect: '/gestao-medica/agendamentos',
                component: RouterView,
                children: [
                    {
                        path: 'agendamentos',
                        name: 'agendamentos',
                        component: AgendamentoIndex,
                        children: [
                            {
                                path: 'solicitacao',
                                name: 'agendamentos.solicitacao',
                                component: AgendamentoFormSolicitacao
                            },
                            {
                                path: 'prestador',
                                name: 'agendamentos.prestador',
                                component: AgendamentoFormPrestador
                            },
                            {
                                path: 'exames-procedimentos',
                                name: 'agendamentos.exames.procedimentos',
                                component: AgendamentosFormExamesProcedimento
                            },
                            {
                                path: 'procedimentos',
                                name: 'agendamentos.procedimentos',
                                component: AgendamentoFormProcedimentos
                            },
                            {
                                path: 'solicitacoes',
                                name: 'agendamentos.solicitacoes',
                                component: AgendamentoFormSolicitacoes
                            },
                            {
                                path: 'exames',
                                name: 'agendamentos.exames',
                                component: AgendamentoFormExames
                            }
                        ]
                    }
                ]
            },
            {
                path: 'gestao-medica/formularios',
                name: 'Formulários',
                component: () => import('./pages/gestao_medica/formularios')
            },
            {
                path: 'painel-agendamento',
                name: 'Painel de Agendamento',
                redirect: '/painel-agendamento/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Painel de Agendamento',
                        meta: { permission: 'gestamedica_painel_agendamento:view' },
                        component: PainelAgendamentoIndex
                    }
                ]
            },
            {
                path: 'reserva-horario',
                name: 'Reservas de horários',
                redirect: '/reserva-horario/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Reservas de horários',
                        meta: { permission: 'gestamedica_painel_agendamento:view' },
                        component: ReservaHorarioIndex
                    },
                    {
                        path: 'dia/:data',
                        name: 'Dias da reserva de horario',
                        meta: { permission: 'gestamedica_painel_agendamento:view' },
                        component: ReservaHorarioDia
                    }
                ]
            },
            {
                path: 'liberar-prestador',
                name: 'Liberar Prestador',
                meta: { permission: 'gestamedica_painel_prestador_liberar_prestador:view' },
                component: LiberarPrestador
            },
            {
                path: 'deposito-antecipado',
                name: 'Deposito Antecipado',
                meta: { permission: 'gestamedica_painel_prestador_liberar_prestador:view' },
                component: DepositoAntecipado
            },
            {
                path: 'atualizar-prestador',
                name: 'Atualizar prestador',
                // meta: { permission: 'gestamedica_painel_prestador_liberar_prestador:view' },
                component: AtualizarPrestador
            },
            {
                path: 'painel-atendimento',
                name: 'Painel Atendimento',
                redirect: '/painel-atendimento/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Painel Atendimento',
                        component: PainelAtendimentoIndex
                    }
                ]
            },
            {
                path: 'agenda',
                name: 'Agenda',
                redirect: '/agenda/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Agendas',
                        component: () => import('@/pages/agenda/List.vue')
                    },
                    {
                        path: ':id',
                        name: 'Edição de Agenda',
                        component: () => import('@/pages/agenda/Form.vue')
                    },
                    {
                        path: 'new',
                        name: 'Nova Agenda',
                        component: () => import('@/pages/agenda/Form.vue')
                    },
                    {
                        path: ':id/grade',
                        name: 'Grade',
                        component: RouterView,
                        children: [
                            {
                                path: '',
                                name: 'Grade',
                                component: () => import('@/pages/agenda/Grade.vue')
                            },
                            {
                                path: 'periodo',
                                name: 'novoPeriodo',
                                component: () => import('@/pages/agenda/Periodo.vue')
                            },
                            {
                                path: 'periodo/:idPeriodo',
                                name: 'editarPeriodo',
                                component: () => import('@/pages/agenda/Periodo.vue')
                            },
                            {
                                path: 'excecao',
                                name: 'novaExcecao',
                                component: () => import('@/pages/agenda/Excecao.vue')
                            },
                            {
                                path: 'excecao/:idExcecao',
                                name: 'editarExcecao',
                                component: () => import('@/pages/agenda/Excecao.vue')
                            }
                        ]
                    }
                ]
            },
            {
                path: 'ambiente',
                name: 'ambiente',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'ambienteList',
                        component: () => import('@/pages/ambiente/List.vue')
                    },
                    {
                        path: 'new',
                        name: 'ambienteNew',
                        component: () => import('@/pages/ambiente/Form.vue'),
                        meta: {
                            title: 'Novo Ambiente'
                        }
                    },
                    {
                        path: ':idAmbiente',
                        name: 'ambienteEdit',
                        component: () => import('@/pages/ambiente/Form.vue'),
                        meta: {
                            title: 'Editar Ambiente'
                        }
                    }
                ]
            },
            {
                path: 'configuracoes-gerais',
                name: 'Configuracoes Gerais',
                component: () => import('@/pages/configuracoes-gerais/List.vue')
            },
            {
                path: 'acesso-soc',
                name: 'Acesso ao SOC',
                meta: { permission: 'configuracoes_usuario:acessoSoc' },
                component: () => import('@/pages/acesso-soc/Form.vue')
            },
            {
                path: 'customers',
                name: 'clientes',
                redirect: '/customers/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Clientes',
                        meta: { permission: 'estrutura_cliente:view' },
                        component: ClientesList
                    },
                    {
                        path: 'new',
                        name: 'Novo Cliente',
                        meta: { permission: 'estrutura_cliente:criar' },
                        component: ClientesForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Cliente',
                        meta: { permission: 'estrutura_cliente:editar' },
                        component: ClientesForm
                    }
                ]
            },
            {
                path: 'grupos',
                name: 'grupos',
                redirect: '/grupos/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Grupos',
                        meta: { permission: 'estrutura_grupos:view' },
                        component: GrupoList
                    },
                    {
                        path: 'new',
                        name: 'Novo Grupo',
                        meta: { permission: 'estrutura_grupos:criar' },
                        component: GrupoForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Grupo',
                        meta: { permission: 'estrutura_grupos:editar' },
                        component: GrupoForm
                    }
                ]
            },
            {
                path: 'companies-exporta-dados',
                name: 'Companies exporta dados',
                redirect: '/companies-exporta-dados/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Companies exporta dados',
                        component: CompaniesExportaDadosList
                    },
                    {
                        path: 'new',
                        name: 'Novo Companies exporta dados',
                        component: CompaniesExportaDadosForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Companies exporta dados',
                        component: CompaniesExportaDadosForm
                    }
                ]
            },
            {
                path: 'department',
                name: 'setor',
                redirect: '/department/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Setores',
                        meta: { permission: 'estrutura_setor:view' },
                        component: SetorList
                    },
                    {
                        path: 'new',
                        name: 'Novo Setor',
                        meta: { permission: 'estrutura_setor:criar' },
                        component: SetorForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Setor',
                        meta: { permission: 'estrutura_setor:editar' },
                        component: SetorForm
                    }
                ]
            },
            {
                path: 'position',
                name: 'cargo',
                redirect: '/position/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Cargos',
                        meta: { permission: 'estrutura_cargo:view' },
                        component: CargoList
                    },
                    {
                        path: 'new',
                        name: 'Novo Cargo',
                        meta: { permission: 'estrutura_cargo:criar' },
                        component: CargoForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Cargo',
                        meta: { permission: 'estrutura_cargo:editar' },
                        component: CargoForm
                    }
                ]
            },
            {
                path: 'hierarchy',
                name: 'hierarquia',
                redirect: '/hierarchy/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Hierarquias',
                        meta: { permission: 'estrutura_hierarquia:view' },
                        component: HierarquiaList
                    },
                    {
                        path: 'new',
                        name: 'Novo Hierarquia',
                        meta: { permission: 'estrutura_hierarquia:criar' },
                        component: HierarquiaForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Hierarquia',
                        meta: { permission: 'estrutura_hierarquia:editar' },
                        component: HierarquiaForm
                    }
                ]
            },
            {
                path: 'branch',
                name: 'unidade',
                redirect: '/branch/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Unidades',
                        meta: { permission: 'estrutura_unidade:view' },
                        component: UnidadeList
                    },
                    {
                        path: 'new',
                        name: 'Nova Unidade',
                        meta: { permission: 'estrutura_unidade:criar' },
                        component: UnidadeForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Unidade',
                        meta: { permission: 'estrutura_unidade:editar' },
                        component: UnidadeForm
                    }
                ]
            },
            {
                path: 'employee',
                name: 'funcionario',
                redirect: '/employee/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Funcinário',
                        meta: { permission: 'estrutura_funcionario:view' },
                        component: FuncionarioList
                    },
                    {
                        path: 'new',
                        name: 'Novo Funcionário',
                        meta: { permission: 'estrutura_funcionario:criar' },
                        component: FuncionarioForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Funcionário',
                        meta: { permission: 'estrutura_funcionario:editar' },
                        component: FuncionarioForm
                    }
                ]
            },
            {
                path: 'risco-procedimento',
                name: 'Riscos procedimentos',
                redirect: '/risco-procedimento/list',
                component: RouterView,
                children: [
                    {
                        path: 'new',
                        name: 'Novo Risco/Procedimento',
                        component: RiscoProcedimentoForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Risco/Procedimento',
                        component: RiscoProcedimentoForm
                    }
                ]
            },
            {
                path: 'procedure',
                name: 'exames',
                redirect: '/procedure/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Exames',
                        meta: { permission: 'gestamedica_painel_cadastro_exames:view' },
                        component: ExameList
                    },
                    {
                        path: 'new',
                        name: 'Novo Exame',
                        component: ExameForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Exame',
                        component: ExameForm
                    }
                ]
            },
            {
                path: 'medic',
                name: 'medicos',
                redirect: '/medic/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Médicos',
                        meta: { permission: 'gestamedica_painel_cadastro_medicos:view' },
                        component: MedicoList
                    },
                    {
                        path: 'new',
                        name: 'Novo Médico',
                        component: MedicoForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Médico',
                        component: MedicoForm
                    }
                ]
            },
            {
                path: 'profissionais',
                name: 'Listagem de profissionais',
                redirect: '/profissionais/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de profissionais',
                        component: ProfissionaisList
                    },
                    {
                        path: 'new',
                        name: 'Novo Profissional',
                        component: ProfissionaisForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Profissional',
                        component: ProfissionaisForm
                    }
                ]
            },
            {
                path: 'riscos',
                name: 'riscos',
                redirect: '/riscos/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de risco',
                        component: RiscoList
                    },
                    {
                        path: 'new',
                        name: 'Novo risco',
                        component: RiscoForm
                    },
                    {
                        path: ':id',
                        name: 'Editar risco',
                        component: RiscoForm
                    }
                ]
            },
            {
                path: 'risk-procedure',
                name: 'riscoprocedure',
                redirect: '/risk-procedure/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de risco exame',
                        component: RiskProcedureList
                    },
                    {
                        path: 'new',
                        name: 'Novo risco exame',
                        component: RiskProcedureForm
                    },
                    {
                        path: ':id',
                        name: 'Editar risco exame',
                        component: RiskProcedureForm
                    }
                ]
            },
            {
                path: 'responsible',
                name: 'responsaveis',
                redirect: '/responsible/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de responsável',
                        component: ResponsibleList
                    },
                    {
                        path: 'new',
                        name: 'Novo responsável',
                        component: ResponsibleForm
                    },
                    {
                        path: ':id',
                        name: 'Editar responsável',
                        component: ResponsibleForm
                    }
                ]
            },
            {
                path: 'security',
                name: 'seguranca',
                redirect: '/security/routes',
                component: RouterView,
                children: [
                    {
                        path: 'routes',
                        name: 'Listagem de Rotas',
                        component: RoutesList
                    },
                    {
                        path: 'newRoutes',
                        name: 'Nova Rota',
                        component: RoutesForm
                    },
                    {
                        path: 'inventario-risco',
                        name: 'Inventário de Risco',
                        component: InventarioRiscoList
                    },
                    {
                        path: 'inventario-risco2',
                        name: 'Inventário de Risco 2',
                        component: InventarioRiscoList2
                    },
                    {
                        path: 'inventario-risco2/aplicacao/:id/',
                        name: 'Inventário de Risco 2 aplicação',
                        component: InventarioRiscoAplicacao,
                        children: [
                            {
                                path: '/security/inventario-risco2/aplicacao/:id/LEVANTAMENTO_PERIGO_RISCO',
                                name: 'LEVANTAMENTO_PERIGO_RISCO',
                                component: LevantamentoRiscoStep
                            },
                            {
                                path: '/security/inventario-risco2/aplicacao/:id/AEP_AET',
                                name: 'AEP_AET',
                                component: AepAetStep
                            },
                            {
                                path: '/security/inventario-risco2/aplicacao/:id/LTCAT_LTIP',
                                name: 'LTCAT_LTIP',
                                component: AplicacaoLaudosStep
                            },
                            {
                                path: '/security/inventario-risco2/aplicacao/:id/PCMSO',
                                name: 'PCMSO',
                                component: PcmsoStep
                            },
                            {
                                path: '/security/inventario-risco2/aplicacao/:id/PGR',
                                name: 'PGR',
                                component: PgrStep
                            }
                        ]
                    },
                    {
                        path: 'libera-hierarquia',
                        name: 'Liberação de Hierarquia',
                        component: LiberaHierarquiaList
                    }
                ]
            },
            {
                path: 'produtos-servicos',
                name: 'Produtos e serviços',
                redirect: '/produtos-servicos/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de produtos e serviços',
                        component: ProdutosServicosList
                    },
                    {
                        path: 'new',
                        name: 'Novo produto serviço',
                        component: ProdutosServicosForm
                    },
                    {
                        path: ':id',
                        name: 'Editar produto serviço',
                        component: ProdutosServicosForm
                    }
                ]
            },
            {
                path: 'EPC',
                name: 'cadastro e epc',
                redirect: '/EPC/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de EPC',
                        component: EPCIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo EPC',
                        component: EPCForm
                    },
                    {
                        path: ':id',
                        name: 'Editar de EPC',
                        component: EPCForm
                    }
                ]
            },
            {
                path: 'profissionais-sesmt',
                name: 'Profissionais SESMT',
                redirect: '/profissionais-sesmt/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Profissionais SESMT',
                        component: ProfissionaisSesmtIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo Profissional SESMT',
                        component: ProfissionaisSesmtForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Profissional SESMT',
                        component: ProfissionaisSesmtForm
                    }
                ]
            },
            {
                path: 'medida-adm-engenharia',
                name: 'Medidas de controle',
                redirect: '/medida-adm-engenharia/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Medidas de controle',
                        component: MedidaAdmEngenhariaIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo Medida de controle',
                        component: MedidaAdmEngenhariaForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Medida de controle',
                        component: MedidaAdmEngenhariaForm
                    }
                ]
            },
            {
                path: 'EPI',
                name: 'cadastro e epi',
                redirect: '/EPI/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de EPI',
                        component: EPIIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo EPI',
                        component: EPIForm
                    },
                    {
                        path: ':id',
                        name: 'Editar de EPI',
                        component: EPIForm
                    }
                ]
            },
            {
                path: 'matriz-config',
                name: 'configuração da matriz',
                redirect: '/matriz-config/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de configuração de matriz',
                        component: MatrizConfigIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo de configuração de matriz',
                        component: MatrizConfigForm
                    },
                    {
                        path: ':id',
                        name: 'Editar de configuração de matriz',
                        component: MatrizConfigForm
                    }
                ]
            },
            {
                path: 'fonte-geradora',
                name: 'fonte-geradora',
                redirect: '/fonte-geradora/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listar fonte geradora',
                        component: FonteGeradoraIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo fonte geradora',
                        component: FonteGeradoraForm
                    },
                    {
                        path: ':id',
                        name: 'Editar fonte geradora',
                        component: FonteGeradoraForm
                    }
                ]
            },
            {
                path: 'competencias',
                name: 'Competências',
                redirect: '/competencias/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listar competências',
                        component: CompetenciasIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo competências',
                        component: CompetenciasForm
                    },
                    {
                        path: ':id',
                        name: 'Editar competências',
                        component: CompetenciasForm
                    }
                ]
            },
            {
                path: 'descricao-risco',
                name: 'descricao-risco',
                redirect: '/descricao-risco/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listar descrição do risco',
                        component: DescricaoRiscoIndex
                    },
                    {
                        path: 'new',
                        name: 'Nova descrição do risco',
                        component: DescricaoRiscoForm
                    },
                    {
                        path: ':id',
                        name: 'Editar descrição do risco',
                        component: DescricaoRiscoForm
                    },
                    {
                        path: 'perfil-exposicao',
                        name: 'Perfil Exposição',
                        redirect: '/perfil-exposicao/list',
                        component: RouterView,
                        children: [
                            {
                                path: 'list',
                                name: 'Listagem de Perfil Exposição',
                                component: PerfilExposicaoIndex
                            },
                            {
                                path: 'new',
                                name: 'Novo Perfil Exposição',
                                component: PerfilExposicaoForm
                            },
                            {
                                path: ':id',
                                name: 'Editar Perfil Exposição',
                                component: PerfilExposicaoForm
                            }
                        ]
                    }
                ]
            },
            {
                path: 'questionario-telemedicina',
                name: 'Questionário Telemedicina',
                redirect: '/questionario-telemedicina/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Questionários',
                        component: QuestionarioTelemedicina
                    },
                    {
                        path: 'new',
                        name: 'Novo questionário',
                        component: QuestionarioTelemedicinaForm
                    },
                    {
                        path: ':id',
                        name: 'Editar questionário',
                        component: QuestionarioTelemedicinaForm
                    }
                ]
            },
            {
                path: 'profiles',
                name: 'perfis',
                redirect: '/profiles/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de perfil',
                        meta: { permission: 'configuracoes_perfil:view' },
                        component: ProfileList
                    },
                    {
                        path: 'new',
                        name: 'Novo perfil',
                        meta: { permission: 'configuracoes_perfil:criar' },
                        component: ProfileForm
                    },
                    {
                        path: ':id',
                        name: 'Editar perfil',
                        meta: { permission: 'configuracoes_perfil:editar' },
                        component: ProfileForm
                    }
                ]
            },
            {
                path: 'supplier',
                name: 'prestadores',
                redirect: '/supplier/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Prestadores',
                        meta: { permission: 'gestamedica_painel_prestador:view' },
                        component: PrestadorList
                    },
                    {
                        path: 'new',
                        name: 'Novo prestador',
                        meta: { permission: 'gestamedica_painel_prestador:criar' },
                        component: PrestadorForm
                    },
                    {
                        path: ':id',
                        name: 'Editar prestador',
                        meta: { permission: 'gestamedica_painel_prestador:editar' },
                        component: PrestadorForm
                    },
                    {
                        path: ':id/procedures',
                        name: 'Listagem de prestadores de procedimento',
                        meta: { permission: 'gestamedica_painel_prestador_procedimento:view' },
                        component: PrestadorProcedimentoList
                    },
                    {
                        path: ':id/procedures-customers',
                        name: 'Listagem de prestadores de procedimento cliente',
                        meta: { permission: 'gestamedica_painel_prestador:cliente' },
                        component: PrestadorProcedimentoClienteList
                    },
                    {
                        path: ':id/horarios-de-atendimento',
                        name: 'Horários de atendimento',
                        meta: { permission: 'gestamedica_painel_prestador:criar' },
                        component: HorarioDeAtendimentoList
                    }
                ]
            },
            {
                path: 'perguntas-sesmt',
                name: 'Perguntas SESMT',
                redirect: '/perguntas-sesmt/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Perguntas SESMT',
                        meta: { permission: 'gestaosesmt_cadastros_questionario_sesmt_perguntas:view' },
                        component: PerguntaSESMTList
                    },
                    {
                        path: 'new',
                        name: 'Nova Pergunta SESMT',
                        meta: { permission: 'gestaosesmt_cadastros_questionario_sesmt_perguntas:criar' },
                        component: PerguntaSESMTForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Pergunta SESMT',
                        meta: { permission: 'gestaosesmt_cadastros_questionario_sesmt_perguntas:editar' },
                        component: PerguntaSESMTForm
                    }
                ]
            },
            {
                path: 'questionarios-sesmt',
                name: 'Questionários SESMT',
                redirect: '/questionarios-sesmt/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Questionários SESMT',
                        meta: { permission: 'gestaosesmt_cadastros_questionario_sesmt:view' },
                        component: QuestionarioSESMTList
                    },
                    {
                        path: 'new',
                        name: 'Novo Questionários SESMT',
                        meta: { permission: 'gestaosesmt_cadastros_questionario_sesmt:criar' },
                        component: QuestionarioSESMTForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Questionários SESMT',
                        meta: { permission: 'gestaosesmt_cadastros_questionario_sesmt:editar' },
                        component: QuestionarioSESMTForm
                    }
                ]
            },
            {
                path: 'perguntas',
                name: 'Perguntas',
                redirect: '/perguntas/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Perguntas',
                        meta: { permission: 'gestamedica_painel_questionario_perguntas:view' },
                        component: PerguntaList
                    },
                    {
                        path: 'new',
                        name: 'Nova Pergunta',
                        meta: { permission: 'gestamedica_painel_questionario_perguntas:criar' },
                        component: PerguntaForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Pergunta',
                        meta: { permission: 'gestamedica_painel_questionario_perguntas:editar' },
                        component: PerguntaForm
                    }
                ]
            },
            {
                path: 'questionarios',
                name: 'Questionários',
                redirect: '/questionarios/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Questionário',
                        meta: { permission: 'gestamedica_painel_questionario_questionarios:view' },
                        component: QuestionarioList
                    },
                    {
                        path: 'new',
                        name: 'Novo Questionário',
                        meta: { permission: 'gestamedica_painel_questionario_questionarios:criar' },
                        component: QuestionarioForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Questionário',
                        meta: { permission: 'gestamedica_painel_questionario_questionarios:editar' },
                        component: QuestionarioForm
                    }
                ]
            },
            {
                path: 'nao-conformidade',
                name: 'non-compliance',
                redirect: '/nao-conformidade/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Não Conformidade',
                        meta: { permission: 'gestamedica_painel_prestador_nao_conformidade:view' },
                        component: NaoConformidadeList
                    },
                    {
                        path: 'new',
                        name: 'Nova Não Conformidade',
                        meta: { permission: 'gestamedica_painel_prestador_nao_conformidade:criar' },
                        component: NaoConformidadeForm
                    },
                    {
                        path: ':id',
                        name: 'Editar Não Conformidade',
                        meta: { permission: 'gestamedica_painel_prestador_nao_conformidade:editar' },
                        component: NaoConformidadeForm
                    }
                ]
            },
            {
                path: 'vehicles',
                name: 'veículos',
                redirect: '/vehicles/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Veículos',
                        component: VehiclesList
                    }
                ]
            },
            {
                path: 'vehiclesScheduling',
                name: 'agendamento de veículos',
                redirect: '/vehiclesScheduling/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Agendamentos de Veículos',
                        component: SchedulingVehiclesList
                    }
                ]
            },
            {
                path: 'equipment',
                name: 'equipamentos',
                redirect: '/equipment/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Equipamentos',
                        component: EquipmentList
                    }
                ]
            },
            {
                path: 'scheduling',
                name: 'agendamentos',
                redirect: '/scheduling/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Agendamentos',
                        component: SchedulingList
                    }
                ]
            },
            {
                path: 'professionals',
                name: 'profissionais',
                redirect: '/professionals/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Listagem de Profissionais',
                        component: ProfessionalsList
                    }
                ]
            },
            {
                path: 'importacao-cliente',
                name: 'importacao-cliente',
                redirect: '/importacao-cliente/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Sincronização',
                        meta: { permission: 'ferramentas_sincronizacao:view' },
                        component: ImportacaoClienteList
                    },
                    {
                        path: 'new',
                        name: 'Nova sincronização',
                        meta: { permission: 'ferramentas_sincronizacao:criar' },
                        component: ImportacaoClienteForm
                    },
                    {
                        path: ':id',
                        name: 'Visualização de sincronização do Cliente',
                        meta: { permission: 'ferramentas_sincronizacao:view' },
                        component: ImportacaoClienteForm
                    }
                ]
            },
            {
                path: '/profile',
                name: 'Perfil',
                component: Profile
            },
            {
                path: '/agendamento-atendimento',
                name: 'telemedicina',
                component: AgendamentoAtendimento
            },
            {
                path: 'esocial',
                name: 'esocial',
                component: RouterView,
                children: [
                    {
                        path: 'dashboard',
                        name: 'Esocial Dashboard',
                        component: EsocialDashboard
                    },
                    {
                        path: 'list',
                        name: 'Listagem de Eventos',
                        component: EsocialList
                    },
                    {
                        path: 'new',
                        name: 'Novo Evento',
                        component: EsocialForm,
                        meta: {
                            title: 'Novo evento'
                        }
                    },
                    {
                        path: ':id',
                        name: 'Editar Evento',
                        component: EsocialForm,
                        meta: {
                            title: 'Editar evento'
                        }
                    }
                ]
            },
            {
                path: '/convocacao-exames',
                name: 'convocacao-exames',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'Listagem de Convocações',
                        component: ConvocacaoExamesList
                    },
                    {
                        path: '/new',
                        name: 'Nova Convocação',
                        component: ConvocacaoExamesForm,
                        children: [
                            {
                                path: '/convocacao-exames/parametros',
                                name: 'Parâmetros convocação',
                                component: () => import('@/pages/convocacao-exames/components/ParametrosPanel.vue')
                            }
                        ]
                    },
                    {
                        path: '/:id',
                        name: 'Visualização de Convocações',
                        component: ConvocacaoExamesForm,
                        children: [
                            {
                                path: '/convocacao-exames/:id/processando-convocacao',
                                name: 'Processando convocação',
                                component: () => import('@/pages/convocacao-exames/components/ProcessandoConvocacaoPanel.vue')
                            },
                            {
                                path: '/convocacao-exames/:id/funcionarios',
                                name: 'funcionarios convocação',
                                component: () => import('@/pages/convocacao-exames/components/FuncionariosPanel.vue')
                            },
                            {
                                path: '/convocacao-exames/:id/confirmacao',
                                name: 'confirmação convocação',
                                component: () => import('@/pages/convocacao-exames/components/ConfirmacaoPanel.vue')
                            },
                            {
                                path: '/convocacao-exames/:id/processando-solicitacao',
                                name: 'Processando solicitação convocação',
                                component: () => import('@/pages/convocacao-exames/components/ProcessandoSolicitacaoPanel.vue')
                            },
                            {
                                path: '/convocacao-exames/:id/concluido',
                                name: 'concluindo convocação',
                                component: () => import('@/pages/convocacao-exames/components/ConcluidoPanel.vue')
                            }
                        ]
                    }
                ]
            },
            {
                path: 'relatorios-e-indicadores',
                name: 'relatorios-e-indicadores',
                redirect: 'relatorios-e-indicadores/solicitacao-por-cliente',
                component: RouterView,
                children: [
                    {
                        path: 'solicitacao-por-cliente',
                        name: ' IndicadoresSolicitação por cliente',
                        component: IndicadoresSolicitacaoPorCliente
                    },
                    {
                        path: 'produtividade',
                        name: 'Indicadores Produtividade',
                        component: IndicadoresProdutividade
                    },
                    {
                        path: 'produtividade-coletiva',
                        name: 'Indicadores Produtividade Coletiva',
                        component: IndicadoresProdutividadeColetiva
                    },
                    {
                        path: 'envio-de-email',
                        name: 'Indicadores Envio de email',
                        component: IndicadoresEnvioDeEmail
                    },
                    {
                        path: 'prestadores',
                        name: 'Indicadores Prestadores',
                        component: IndicadoresPrestadores
                    }
                ]
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                redirect: 'dashboard/estrutura',
                component: RouterView,
                meta: {
                    requiresAuth: true,
                    meta: { permission: 'indicadores_cliente:view' }
                },
                children: [
                    {
                        path: 'estrutura',
                        name: ' Estrutura',
                        component: DashboardEstrutura,
                        meta: { permission: 'indicadores_cliente_estrutura:view' }
                    },
                    {
                        path: 'agendamentos',
                        name: 'Agendamentos',
                        component: DashboardAgendamentos,
                        meta: { permission: 'indicadores_cliente_agendamentos:view' }
                    },
                    {
                        path: 'falta-consulta',
                        name: 'Falta Consulta',
                        component: DashboardFaltaConsulta,
                        meta: { permission: 'indicadores_cliente_falta_consulta:view' }
                    }
                ]
            },
            {
                path: 'manuais',
                name: 'manuais',
                redirect: '/manuais/list',
                component: RouterView,
                children: [
                    {
                        path: 'list',
                        name: 'Lista de manuais do sistema',
                        component: ManualSistemaIndex
                    },
                    {
                        path: 'new',
                        name: 'Novo manual do sistema',
                        component: ManualSistemaForm
                    },
                    {
                        path: ':id',
                        name: 'Editar manual do sistema',
                        component: ManualSistemaForm
                    }
                ]
            },
            gestaoMedica,
            gestaoMedicaV3
        ]
    },
    {
        path: '/check-in',
        redirect: '/check-in/home',
        name: 'check-in',
        component: () => import('./pages/check-in/Index.vue'),
        meta: {
            requiresAuth: true,
            meta: { permission: 'gestamedica_painel_atendimento_check_in:view' }
        },
        children: [
            {
                path: '/check-in/home',
                name: 'checkInValidacao',
                redirect: '/check-in/validacao-check-in',
                component: RouterView,
                children: [
                    {
                        path: '/check-in/validacao-check-in',
                        name: 'Validacao Check-In',
                        component: () => import('./pages/check-in/componentes/ValidacaoCheckIn.vue')
                    },
                    {
                        path: '/check-in/dados-check-in',
                        name: 'Dados Check-In',
                        component: () => import('./pages/check-in/componentes/DadosCheckIn.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/tela-chamada',
        name: 'tela-chamada',
        component: () => import('./pages/tela-chamada/TelaChamada.vue'),
        meta: {
            meta: { permission: 'gestamedica_painel_atendimento_tela_chamada:view' },
            requiresAuth: true
        }
    },
    {
        path: '/cadastros',
        name: 'cadastroColaborador',
        component: CadastroColaborador,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/liberar',
        name: 'Liberar atendimento do prestador',
        component: LiberarPrestador,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/recuperar',
        name: 'recuperar',
        component: () => import('./pages/Recuperar.vue')
    },
    {
        path: '/password/forgot',
        name: 'nova-senha',
        component: () => import('./pages/NovaSenha.vue')
    },
    {
        path: '/atualizar-cadastro-prestador/:token',
        name: 'Atualizar cadastro prestador',
        component: AtualizarCadastroPrestador
    },
    {
        path: '/telemedicina-paciente/:sala',
        name: 'telemedicina paciente',
        component: TelemedicinaPaciente
    },
    {
        path: '/questionario-paciente/:sala',
        name: 'questionário paciente',
        component: TelemedicinaQuestionarioPaciente
    },
    {
        path: '/questionario-funcionario/:token',
        name: 'questionário funcionário',
        component: QuestionarioFuncionario
    },
    {
        path: '/kit-atendimento',
        name: 'Download Anexos Prestador',
        component: DownloadAnexoPrestador
    },
    {
        path: '/qr-code',
        name: 'Qr Code',
        component: GerarQrCode
    },
    {
        path: '/relatorio-levantamento-anexo/:origem/:origemId',
        name: 'Relatório Levantamento Anexo',
        component: RelatorioLevantamento,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pre-admissao',
        name: 'Admissão Digital',
        component: PreAdmissao
    },
    {
        path: '/confirmacao-prestador',
        component: RouterView,
        redirect: '/confirmacao-prestador/login',
        name: 'acesso-prestador',
        children: [
            {
                path: '/confirmacao-prestador/login',
                name: 'Login Confirmação Prestador',
                component: () => import('./pages/confirmacao-prestador/PrestadorLogin.vue')
            },
            {
                path: '/confirmacao-prestador/inicio',
                name: 'Página Prestador',
                redirect: '/confirmacao-prestador/agendamentos',
                component: () => import('./pages/confirmacao-prestador/PrestadorAgendamentos/Index.vue'),
                meta: {
                    requerAutenticacaoPrestador: true
                },
                children: [
                    {
                        path: '/confirmacao-prestador/agendamentos',
                        name: 'Agendamentos Prestador',
                        redirect: '/confirmacao-prestador/agendamentos/pendentes',
                        component: RouterView,
                        children: [
                            {
                                path: '/confirmacao-prestador/agendamentos/pendentes',
                                name: 'Agendamentos Pendentes Prestador',
                                component: () =>
                                    import('./pages/confirmacao-prestador/PrestadorAgendamentos/components/PainelAgendamentosPendentes/Index.vue')
                            },
                            {
                                path: '/confirmacao-prestador/agendamentos/futuros',
                                name: 'Agendamentos Futuros Prestador',
                                component: () =>
                                    import('./pages/confirmacao-prestador/PrestadorAgendamentos/components/PainelAgendamentosFuturos/Index.vue')
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/cadastro-prestador',
        component: RouterView,
        redirect: '/cadastro-prestador/login',
        name: 'cadastro-prestador',
        children: [
            {
                path: '/cadastro-prestador/login',
                name: 'Login Cadastro Prestador',
                component: () => import('./pages/cadastro-prestador/CadastroPrestadorLogin.vue')
            },
            {
                path: '/cadastro-prestador/cadastro',
                name: 'formulario-cadastro-prestador',
                meta: {
                    requerAutenticacaoCadastroPrestador: true
                },
                component: () => import('./pages/cadastro-prestador/CadastroPrestador/CadastroPrestador.vue')
            }
        ]
    },
    autoagendamento
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
