<template>
    <div class="w-full">
        <Message severity="info">
            <p>Por favor confira os dados do solicitante</p>
        </Message>

        <div class="border-round border-1 border-gray-300 p-4 grid m-auto">
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label for="nome">Nome do solicitante<span class="p-error">*</span></label>
                <InputText
                    id="nome"
                    v-model="v$.nomeSolicitante.$model"
                    :class="{ 'p-invalid': v$.nomeSolicitante.$errors.length && v$.nomeSolicitante.lazy.$dirty }"
                />
                <small v-if="v$.nomeSolicitante.$errors.length && v$.nomeSolicitante.lazy.$dirty" class="p-error w-full">
                    {{ v$.nomeSolicitante.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label for="email">E-mail do solicitante<span class="p-error">*</span></label>
                <InputText
                    id="email"
                    v-model="v$.emailSolicitante.$model"
                    :class="{ 'p-invalid': v$.emailSolicitante.$errors.length && v$.emailSolicitante.lazy.$dirty }"
                />
                <small v-if="v$.emailSolicitante.$errors.length && v$.emailSolicitante.lazy.$dirty" class="p-error w-full">
                    {{ v$.emailSolicitante.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label for="telefone">Telefone do solicitante<span class="p-error">*</span></label>
                <InputMask
                    id="telefone"
                    v-model="v$.telefoneSolicitante.$model"
                    mask="(99) 99999-999?9"
                    :class="{ 'p-invalid': v$.telefoneSolicitante.$errors.length && v$.telefoneSolicitante.lazy.$dirty }"
                />
                <small v-if="v$.telefoneSolicitante.$errors.length && v$.telefoneSolicitante.lazy.$dirty" class="p-error w-full">
                    {{ v$.telefoneSolicitante.$errors[0].$message }}
                </small>
            </div>
        </div>

        <div class="flex align-items-center mt-3">
            <InputSwitch id="autoagendamento" v-model="form.autoagendamento" />
            <label for="autoagendamento" class="ml-2"> Autoagendamento </label>
            <i
                class="pi pi-info-circle text-blue-500 ml-1"
                v-tooltip.bottom="{
                    value: `Nesse processo você seleciona as datas
                    e o colaborador realiza o autoagendamento,
                    escolhendo a clínica e o horário de preferência.`,
                    pt: {
                        text: 'font-medium w-max'
                    }
                }"
            />
        </div>
        <div class="mt-5" v-if="form.autoagendamento">
            <p class="text-lg">Enquadramento e emissão de laudo PCD<span class="p-error">*</span></p>
            <div class="flex gap-5">
                <div class="field-radiobutton">
                    <RadioButton name="nao" value="nao" v-model="form.enquadramentoLaudoPCD"/>
                    <label for="nao">Não</label>
                </div>
                <div class="field-radiobutton">
                    <RadioButton name="sim" value="sim" v-model="form.enquadramentoLaudoPCD"/>
                    <label for="sim">Sim</label>
                </div>
            </div>
            <small v-if="v$.enquadramentoLaudoPCD.$errors.length && v$.enquadramentoLaudoPCD.$dirty" class="p-error w-full">
                {{ v$.enquadramentoLaudoPCD.$errors[0].$message }}
            </small>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, computed, defineExpose, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required, requiredIf } from '@vuelidate/validators';
import { getProfile } from '@/services/auth';
import validacaoPersonalizadaTelefone from './utils/validacaoPersonalizadaTelefone';

const emit = defineEmits(['update:formData']);

const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});

const rules = {
    nomeSolicitante: { required: helpers.withMessage('Campo obrigatório', required), lazy: true },
    emailSolicitante: {
        email: helpers.withMessage('Campo deve ser email', email),
        required: helpers.withMessage('Campo obrigatório', required),
        lazy: true
    },
    telefoneSolicitante: {
        required: helpers.withMessage('Campo obrigatório', required),
        lazy: true,
        validacaoPersonalizadaTelefone: helpers.withMessage('Telefone inválido', validacaoPersonalizadaTelefone)
    },
    enquadramentoLaudoPCD: { required: helpers.withMessage('Este campo é obrigatório', requiredIf(() => form.value.autoagendamento)) },
};

const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

const v$ = useVuelidate(rules, form);

onMounted(async () => {
    form.value.profile = await getProfile();
    form.value.nomeSolicitante = form.value.profile.name;
    form.value.emailSolicitante = form.value.profile.email;
    form.value.telefoneSolicitante = form.value.profile.phone;
});

defineExpose({
    validarFormulario() {
        return v$.value.$validate();
    }
});
</script>
