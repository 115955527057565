<template>
        <Button v-if="tourName" icon="pi pi-info-circle" @click="toorTela"  class="p-button-rounded p-button-link custom-button" />
</template>

<script>
import { EstruturaCargoList } from '../manual-guiado/estruturaCargo';
import { EstruturaClienteList } from '../manual-guiado/estruturaCliente';
import { EstruturaUnidadeList } from '../manual-guiado/estruturaUnidade';
import { EstruturaGrupoList } from '../manual-guiado/estruturaGrupo';
import { EstruturaSetorList } from '../manual-guiado/estruturaSetor';
import { EstruturaFuncionarioList } from '../manual-guiado/estruturaFuncionario';
import { EstruturaHierarquiaList } from '../manual-guiado/estruturaHierarquia';
import { GestaoMedicaDepositoAntecipado } from '../manual-guiado/gestaoMedicaDepositoAntecipado'
import { GestaoMedicaPrestador } from '../manual-guiado/gestaoMedicaPrestador'
import { GestaoMedicaQuestionarioTelemedicina } from '../manual-guiado/gestoMedicaQuestionarioTelemedicina'
import { GestaoMedicaQuestionario } from '../manual-guiado/gestaoMedicaQuestionario'
import { GestaoMedicaPerguntas } from '../manual-guiado/gestaoMedicaPerguntas'
import { GestaoSesmtAtividadeEspecifica } from '../manual-guiado/gestaoSesmtAtividadeEspecifica'
import { GestaoSesmtProcedimento } from '../manual-guiado/gestaoSesmtProcedimento'
import { GestaoSesmtFonteGeradora } from '../manual-guiado/gestaoSesmtFonteGeradora'
import { GestaoSesmtCompetencia } from '../manual-guiado/gestaoSesmtCompetencias'
import { GestaoSesmtDescricaoRisco } from '../manual-guiado/gestaoSesmtDescricaoDoRisco'
import { GestaoSesmtEpc } from '../manual-guiado/gestaoSesmtEpc'
import { GestaoSesmtEpi } from '../manual-guiado/gestaoSesmtEpi'
import { GestaoSesmtMatrizAvaliacaoDeRisco } from '../manual-guiado/gestaoSesmtMatrizAvaliacaoDeRisco'
import { GestaoSesmtProfissionaisSesmt } from '../manual-guiado/gestaoSesmtProfissionaisSesmt'
import { GestaoSesmtMedidasDeControle } from '../manual-guiado/gestaoSesmtMedidasDeControle'
import { GestaoSesmtPerigoFatorRisco } from '../manual-guiado/gestaoSesmtPerigoFatorRisco'
import { GestaoSesmtProdutosServicos } from '../manual-guiado/gestaoSesmtProdutosServicos'
import { FerramentaSincronizacao } from '../manual-guiado/ferramentaSincronizacao'
import { FerramentaAgendaMedica } from '../manual-guiado/ferramentaAgendaMedica'
import { BaseEmpresa } from '../manual-guiado/baseEmpresa'
import { BaseUsuarios } from '../manual-guiado/baseUsuarios'
import { BasePerfil } from '../manual-guiado/basePerfil'
import { BaseImportacao } from '../manual-guiado/baseImportacao';
import { BaseImportacaoCadastroEstrutura } from '../manual-guiado/baseImportacaoCadastroEstrutura'
import { BaseApollus } from '../manual-guiado/baseApollus'
import { BaseBoletins } from '../manual-guiado/baseBoletins'
import { BaseFeriados } from '../manual-guiado/baseFeriados'
import { BaseEmails } from '../manual-guiado/baseEmails'
import { SaudeGerais } from '../manual-guiado/saudeGerais'
import { GestaoSesmtPerguntas } from '../manual-guiado/gestaoSesmtPerguntas'
import { GestaoSesmtQuestionario } from '../manual-guiado/gestaoSesmtQuestionario'
import { GestaoMedicaPainelAgendamento } from '../manual-guiado/gestaoMedicaPainelAgendamento'
import { GestaoMedicaChamadaProfissional } from '../manual-guiado/gestaoMedicaChamadaProfissionalList'
import {
    IndexGuiaGestaoMedica,
    ExamesGuiaGestaoMedica,
    DefinirClinicaGuiaGestaoMedica,
    AgendarHorarioGuiaGestaoMedica,
    DadosAgendamentoGuiaGestaoMedica,
    TelaSolicitacoesGuiaGestaoMedica
} from '../manual-guiado/guiaGestaoMedica';



export default {
    props: {
        tourName: {
            type: String,
            required: true
        }
    },
    methods: {
        toorTela() {
            this.tour = this.$shepherd({
                useModalOverlay: true,
                scrollTo: true,
                tourName: this.tourName
            });

            const steps = this.getTourSteps();

            this.tour.addSteps(steps);
            this.tour.start();
        },
        getTourSteps() {
            switch (this.tourName) {
                case 'IndexGuiaGestaoMedica':
                    return IndexGuiaGestaoMedica.call(this);
                case 'ExamesGuiaGestaoMedica':
                    return ExamesGuiaGestaoMedica.call(this);
                case 'DefinirClinicaGuiaGestaoMedica':
                    return DefinirClinicaGuiaGestaoMedica.call(this);
                case 'AgendarHorarioGuiaGestaoMedica':
                    return AgendarHorarioGuiaGestaoMedica.call(this);
                case 'DadosAgendamentoGuiaGestaoMedica':
                    return DadosAgendamentoGuiaGestaoMedica.call(this);
                case 'TelaSolicitacoesGuiaGestaoMedica':
                    return TelaSolicitacoesGuiaGestaoMedica.call(this);
                case 'position-list':
                    return EstruturaCargoList.call(this);     
                case 'customers-list':
                    return EstruturaClienteList.call(this);
                case 'branch-list':
                    return EstruturaUnidadeList.call(this);    
                case 'grupos-list':
                    return EstruturaGrupoList.call(this);
                case 'department-list':
                    return EstruturaSetorList.call(this);  
                case 'employee-list':
                    return EstruturaFuncionarioList.call(this);  
                case 'hierarchy-list':
                    return EstruturaHierarquiaList.call(this);  
                case 'deposito-antecipado':
                    return GestaoMedicaDepositoAntecipado.call(this);  
                case 'supplier-list':
                    return GestaoMedicaPrestador.call(this);  
                case 'questionario-telemedicina-list':
                    return GestaoMedicaQuestionarioTelemedicina.call(this);  
                case 'perguntas-list':
                    return GestaoMedicaPerguntas.call(this);  
                case 'questionarios-list':
                    return GestaoMedicaQuestionario.call(this);  
                case 'atividades-especificas-list':
                    return GestaoSesmtAtividadeEspecifica.call(this); 
                case 'procedure-list':
                    return GestaoSesmtProcedimento.call(this);
                case 'fonte-geradora-list':
                    return GestaoSesmtFonteGeradora.call(this);
                case 'competencias-list':
                    return GestaoSesmtCompetencia.call(this);
                case 'descricao-risco-list':
                    return GestaoSesmtDescricaoRisco.call(this);
                case 'epc-list':
                    return GestaoSesmtEpc.call(this);
                case 'epi-list':
                    return GestaoSesmtEpi.call(this);
                case 'matriz-config-list':
                    return GestaoSesmtMatrizAvaliacaoDeRisco.call(this);
                case 'profissionais-sesmt-list':
                    return GestaoSesmtProfissionaisSesmt.call(this);
                case 'medida-adm-engenharia-list':
                    return GestaoSesmtMedidasDeControle.call(this);
                case 'riscos-list':
                    return GestaoSesmtPerigoFatorRisco.call(this);
                case 'produtos-servicos-list':
                    return GestaoSesmtProdutosServicos.call(this);
                case 'importacao-cliente-list':
                    return FerramentaSincronizacao.call(this);
                case 'agenda-list':
                    return FerramentaAgendaMedica.call(this);  
                case 'companies-list':
                    return BaseEmpresa.call(this);
                case 'users-list':
                    return BaseUsuarios.call(this);  
                case 'profiles-list':
                    return BasePerfil.call(this);  
                case 'importacao-cadastro-estrutura-list':
                    return BaseImportacaoCadastroEstrutura.call(this);
                case 'importacao-list':
                    return BaseImportacao.call(this);   
                case 'integracao-apollus-list':
                    return BaseApollus.call(this);     
                case 'boletins-list':
                    return BaseBoletins.call(this);  
                case 'configuracoes_feriados-list':
                    return BaseFeriados.call(this);  
                case 'mail-list':
                    return BaseEmails.call(this); 
                case 'configuracoes-gerais':
                    return SaudeGerais.call(this);       
                case 'perguntas-sesmt-list':
                    return GestaoSesmtPerguntas.call(this);   
                case 'questionarios-sesmt-list':
                    return GestaoSesmtQuestionario.call(this); 
                case 'painel-agendamento':
                    return GestaoMedicaPainelAgendamento.call(this); 
                case 'tela-chamada-profissional-list':
                    return GestaoMedicaChamadaProfissional.call(this);         
                default:
                    return [];
            }
        }
    }
};
</script>
<style>

.custom-button {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background: none;
    overflow: hidden;
}

.custom-button .pi {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5rem;
    height: 1.5rem;
}

</style>
