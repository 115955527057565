/**
 * Function for show toast of error on screen.
 * @param toast         Instance of toast
 * @param error         Error captured on try/catch
 * @param customMessage Custom message for show on toast
 * @param life Time in milliseconds for which the toast will be displayed. Default is 3000 milliseconds.
 */
export function showError(toast, error, customMessage = 'Ocorreu algum problema', life = 3000) {
    const detailsMessages = error?.response?.data?.details?.response?.message;
    const messages = Array.isArray(detailsMessages) ? detailsMessages.join() : detailsMessages;
    const message = messages || error?.response?.data?.message || error.message;

    toast.add({
        severity: 'error',
        summary: customMessage,
        detail: message,
        life
    });
}

/**
 * Function for show toast of success on screen.
 * @param toast         Instance of toast
 * @param customMessage Custom message for show on toast
 * @param life Time in milliseconds for which the toast will be displayed. Default is 5000 milliseconds.
 */
export function showSuccess(toast, customMessage = 'Registro criado', life = 5000) {
    toast.add({
        severity: 'success',
        summary: customMessage,
        life
    });
}

/**
 * Function for show toast of warning on screen.
 * @param toast         Instance of toast
 * @param customMessage Custom message for show on toast
 * @param life Time in milliseconds for which the toast will be displayed. Default is 5000 milliseconds.
 */
export function showWarning(toast, customMessage, life = 5000) {
    toast.add({
        severity: 'warn',
        summary: customMessage,
        life
    });
}

/**
 * Function for show toast of info on screen.
 * @param toast         Instance of toast
 * @param customMessage Custom message for show on toast
 * @param life Time in milliseconds for which the toast will be displayed. Default is 5000 milliseconds.
 */
export function showInfo(toast, customMessage, life = 5000) {
    toast.add({
        severity: 'info',
        summary: customMessage,
        life
    });
}
