<template>
    <Dialog
        v-model:visible="open"
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :style="{ width: '60vw' }"
        class="p-fluid"
        :modal="true"
        header="Reserva de horários"
        :closable="!loadingConfirmar"
    >
        <div v-if="loadingConfirmar" class="div-loading">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <Message severity="info">Aguarde estamos inserindo os agendamentos no prestador. Esta operação pode demorar. Aguarde.</Message>
        </div>
        <div v-if="loadingBuscandoHorarios" class="div-loading">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <Message severity="info">Aguarde. Estamos buscando os melhores horários disponíveis para a data selecionada.</Message>
        </div>
        <div class="div-content" :style="loadingConfirmar || loadingBuscandoHorarios ? 'opacity: 30%;' : ''">
            <AppLoadingWrapper v-if="loading" />
            <div v-for="(grupo, indexGrupo) in recordsAgrupados" :key="indexGrupo" class="p-panel-content">
                <Fieldset :legend="grupo.title" class="mb-4">
                    <div class="grid">
                        <div class="col-2" v-if="!grupo.prestadorInformado"><strong>Exame particular</strong></div>
                        <div class="col-3"><strong>Prestador</strong></div>
                        <div class="col-3"><strong>Exames</strong></div>
                        <div class="col-2"><strong>Dias de antecedência</strong></div>
                        <div :class="!grupo.prestadorInformado ? 'col-2' : 'col-4'"><strong>Data</strong></div>
                    </div>
                    <div v-for="(item, index) in grupo.items" :key="index" class="p-panel-content">
                        <div class="grid">
                            <div v-if="!item.prestadorInformado" class="col-2">
                                <div class="field-checkbox h-full flex align-items-center">
                                    <InputSwitch id="binary" v-model="item.exameParticular" />
                                    <label for="binary">Particular</label>
                                </div>
                            </div>
                            <div class="col-3">
                                <DropdownPrestadorCliente
                                    v-model="item.prestador"
                                    :idCliente="agendamento.cliente.id"
                                    :idCidade="agendamento.cidade.id"
                                    :idExame="getIdsExames(item)"
                                    :disabled="item.prestadorInformado || item.exameParticular"
                                    :autoLoad="!item.prestadorInformado && !item.exameParticular"
                                />
                            </div>
                            <div class="col-3 flex align-items-center">
                                {{ item.nomesExames }}
                            </div>
                            <div class="col-2 mt-3">
                                {{ item.diasAntecedencia }}
                            </div>
                            <div :class="!item.prestadorInformado ? 'col-2' : 'col-4'">
                                <Dropdown
                                    v-if="item.prestadorInformado"
                                    placeholder="Horarios da agenda"
                                    v-model="item.horario"
                                    :options="horariosDisponiveis[item.nomesExames]"
                                    @change="changeHorario(item)"
                                    @before-show="carregarSeNecessario(item)"
                                    :loading="loadingHorarios[item.nomesExames]"
                                    :disabled="!podePreencherHorario(item)"
                                >
                                    <template #value="{ placeholder, value }">
                                        <span v-if="value && value.horarioChegada">
                                            {{ $filters.formatDayMonth(value.horarioChegada) }} | Entrada {{ formatTimeUtc(value.horarioChegada) }} -
                                            Saída {{ formatTimeUtc(value.horarioSaida) }} ({{ value.minutosAgendas }}
                                            min)
                                        </span>
                                        <span v-else>
                                            {{ placeholder }}
                                        </span>
                                    </template>
                                    <template #option="{ option }">
                                        <span
                                            >{{ $filters.formatDayMonth(option.horarioChegada) }} | Entrada
                                            {{ formatTimeUtc(option.horarioChegada) }} - Saída {{ formatTimeUtc(option.horarioSaida) }} ({{
                                                option.minutosAgendas
                                            }}
                                            min)
                                        </span>
                                    </template>
                                </Dropdown>
                                <small v-if="loadingHorarios[item.nomesExames]" class="p-info">Aguarde buscando horários no prestador.</small>
                                <Calendar
                                    v-if="!item.prestadorInformado"
                                    v-model="item.horario"
                                    dateFormat="dd/mm/yy"
                                    selectionMode="single"
                                    :showIcon="true"
                                />
                            </div>
                        </div>
                    </div>
                    <Message v-if="grupo.info" severity="info"> {{ grupo.info }}</Message>
                </Fieldset>
            </div>
        </div>
        <template #footer>
            <div>
                <Button
                    :disabled="loadingConfirmar"
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-danger"
                    @click="closeDialog"
                />
                <Button
                    label="Confirmar"
                    :loading="loadingConfirmar"
                    icon="pi pi-check"
                    class="p-button-outlined p-button-success"
                    @click="onClickConfirmar"
                />
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineProps, defineExpose, defineEmits } from 'vue';
import { useGlobalFilters } from '@/utils/useGlobalFilters';
import { flatten } from 'ramda';
import AreaMedicaService from '@/services/AreaMedicaService';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';
import * as moment from 'moment-timezone';
import DropdownPrestadorCliente from '@/pages/prestadores/components/DropdownPrestadorCliente.vue';

const emit = defineEmits(['load']);

const props = defineProps({
    agendamentoId: {
        type: Number,
        required: true
    }
});

const open = ref(false);
const $filters = useGlobalFilters();
const loadingConfirmar = ref(false);
const loadingBuscandoHorarios = ref(false);
const loadingHorarios = ref({});
const loading = ref(false);
const recordsAgrupados = ref([]);
const horariosDisponiveis = ref({});
const records = ref([]);
const exames = ref([]);
const agendamento = ref();
const idsExames = ref({});
const toast = useToast();

function openDialog() {
    open.value = true;
    loadData();
}

function closeDialog() {
    open.value = false;
}

async function loadData() {
    try {
        loading.value = true;
        const serviceAgendamentos = new AreaMedicaService('agendamentos');
        const [responseReserva, responseAgendamento] = await Promise.all([
            getClientBase().get('agendamentos/reservas-horarios/' + props.agendamentoId),
            serviceAgendamentos.findById(props.agendamentoId)
        ]);
        records.value = responseReserva.data;
        recordsAgrupados.value = [
            {
                prestadorInformado: true,
                title: 'Exames com prestador informado',
                items: records.value.filter((p) => p.prestadorInformado)
            },
            {
                prestadorInformado: false,
                title: 'Exames sem prestador informado',
                info: 'Procedimentos devem ser agendados diretamente com o prestador informado.',
                items: records.value.filter((p) => !p.prestadorInformado)
            }
        ].filter((p) => p.items.length);
        const examesAgrupados = recordsAgrupados.value.map((p) => {
            if (!p.prestadorInformado) {
                return p.items.map((q) => q.exames.map((r) => r));
            }
        });
        exames.value = flatten(examesAgrupados);
        exames.value = exames.value.filter((p) => p?.id);
        agendamento.value = responseAgendamento.data;
        records.value.forEach((p) => {
            horariosDisponiveis[p.nomesExames] = [];
        });
        await carregarTodosHorarios();
        loading.value = false;
    } catch (error) {
        loading.value = false;
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
}

async function carregarTodosHorarios() {
    for (const record of records.value) {
        if (isPrimeiro(record)) {
            await carregarHorarios(record);
        } else {
            const anterior = getAnterior(record);
            if (anterior.horario && record.prestadorInformado) {
                await carregarHorarios(record);
            }
        }
    }
}

function isPrimeiro(record) {
    const index = records.value.indexOf(record);
    return index <= 0;
}

async function carregarHorarios(record) {
    try {
        const horarios = horariosDisponiveis.value[record.nomesExames];
        if (horarios && horarios.length) {
            return;
        }
        const dto = {
            prestadorId: agendamento.value.idPrestador,
            funcionarioId: agendamento.value.idFuncionario,
            ambienteIds: record.ambientes.map((p) => p.id),
            examesIds: record.exames.map((p) => p.id),
            dataInicial: agendamento.value.dataSugestaoInicial,
            dataFinal: agendamento.value.dataSugestaoFinal,
            periodo: agendamento.value.periodo
        };
        if (!isPrimeiro(record)) {
            const anterior = getAnterior(record);
            const dataInicialMoment = moment(anterior.horario.horarioChegada).add(anterior.diasAntecedencia, 'd');
            const dataInicial = dataInicialMoment.toDate();
            const dataFinal = dataInicialMoment.add(3, 'd');
            dto.dataInicial = dataInicial;
            dto.dataFinal = dataFinal;
        }
        loadingHorarios.value[record.nomesExames] = true;
        loadingBuscandoHorarios.value = true;
        const { data } = await getClientBase().post('agendamentos/faixas-disponiveis', dto);
        horariosDisponiveis.value[record.nomesExames] = data;
        loadingHorarios.value[record.nomesExames] = false;
        loadingBuscandoHorarios.value = false;
        if (!data?.length) {
            toast.add({
                severity: 'error',
                summary: 'Sem horários disponíveis para a data/período informado. Tente informar outra data de sugestão.',
                life: 3000
            });
        }
    } catch (error) {
        loadingHorarios.value[record.nomesExames] = false;
        loadingBuscandoHorarios.value = false;
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
}

function getAnterior(record) {
    const index = records.value.indexOf(record);
    return records.value[index - 1];
}

function getIdsExames(record) {
    const found = idsExames.value[record.nomesExames];
    if (!found) {
        idsExames.value[record.nomesExames] = record.exames?.map((p) => p.id) ?? [];
    }
    return idsExames.value[record.nomesExames];
}

async function changeHorario(record) {
    limparLinhasAbaixo(record);
    await carregarTodosHorarios();
}

function limparLinhasAbaixo(record) {
    const posicao = records.value.indexOf(record);
    for (let index = posicao + 1; index < records.value.length; index++) {
        records.value[index].horario = null;
        horariosDisponiveis.value[records.value[index].nomesExames] = [];
    }
}

async function carregarSeNecessario(record) {
    if (loadingHorarios.value[record.nomesExames]) {
        return;
    }
    await carregarHorarios(record);
}

function podePreencherHorario(record) {
    if (isPrimeiro(record)) {
        return true;
    }
    const anterior = getAnterior(record);
    return !!anterior.horario;
}

function formatTimeUtc(hora) {
    return moment.tz(hora, 'UTC').format('HH:mm');
}

async function onClickConfirmar() {
    try {
        loadingConfirmar.value = true;
        const lista = records.value.map((record) => {
            return {
                prestadorId: record.prestador?.id,
                examesIds: record.exames.map((q) => q.id),
                diasAntecedencia: record.diasAntecedencia,
                prestadorInformado: record.prestadorInformado,
                horarioSemPrestador: !record.prestadorInformado ? record.horario : null,
                itens: record.horario?.itens,
                exameParticular: record.exameParticular
            };
        });
        await getClientBase().post('agendamentos/reservas-horarios/' + props.agendamentoId, lista);
        loadingConfirmar.value = false;
        emit('load');
    } catch (error) {
        loadingConfirmar.value = false;
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
}

defineExpose({
    openDialog
});
</script>

<style lang="scss" scoped>
.div-loading {
    margin-top: -40px;
    margin-left: 200px;
    position: absolute;
    z-index: 9999;
}
</style>
