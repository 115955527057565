<template>
    <MultiSelect
        class="app-multiselect"
        v-bind="$attrs"
        v-model="value"
        :options="optionItems"
        :filter="true"
        @filter="filtrar"
        :optionLabel="optionLabel"
        :optionValue="optionValue"
        :loading="loading"
        :placeholder="placeholder"
        display="chip"
        ref="primeMultiselect"
    >
        <template #option="slotProps">
            <div v-if="renderOption" :style="localStyleOptions(slotProps)">
                {{ this.renderOption(slotProps) }}
            </div>
            <div v-else-if="slotProps.option && optionLabel" :style="localStyleOptions(slotProps)">
                {{ slotProps.option[this.optionLabel] }}
            </div>
            <span v-else :style="localStyleOptions(slotProps)">
                {{ slotProps.option }}
            </span>
        </template>
    </MultiSelect>
</template>
<script>
export default {
    name: 'AppMultiselect',
    props: {
        modelValue: {
            type: Object
        },
        optionLabel: {
            type: String,
            required: true
        },
        optionValue: {
            type: String,
            default: null
        },
        itemSize: {
            type: Number,
            default: 20
        },
        service: {
            type: Object,
            required: true
        },
        queryDelay: {
            type: Number,
            default: 500
        },
        placeholder: {
            type: String
        },
        renderOption: {
            type: Function
        },
        autoLoad: {
            type: Boolean,
            default: true
        },
        styleOptions: {
            type: Function
        },
        filtrosExtras: {
            type: Object
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            loading: false,
            filter: null,
            optionItems: [{}],
            selectedRecords: [],
            timeout: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    watch: {
        async service() {
            if (this.autoLoad) {
                await this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
            }
        },
        modelValue() {
            if (this.autoLoad) {
                this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
            }
        }
    },
    async mounted() {
        if (this.autoLoad) {
            await this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
        }
    },
    methods: {
        async filtrar(event) {
            this.filter = event.value;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                if (event.value) {
                    this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
                }
            }, this.queryDelay);
        },
        async load({ limit, filter, filtrosExtras }) {
            this.loading = true;
            const { data } = await this.service.findAll({ limit, filter, filtrosExtras });

            const dataItensIds = data.items.map((p) => p.id);
            const selectedValues = this.value || [];
            const selectedValuesInOtherPage = selectedValues.filter((p) => !dataItensIds.includes(p.id));

            this.optionItems = [...data.items, ...selectedValuesInOtherPage];
            this.loading = false;
        },
        localStyleOptions(slotProps) {
            if (this.styleOptions) {
                return this.styleOptions(slotProps);
            }
            return null;
        }
    }
};
</script>
<style lang="scss">
.app-multiselect .p-multiselect-label-container {
    padding: 0.25rem;
}
</style>
