<template>
    <!--        :class="{ 'p-invalid': required && !value }"-->
    <Calendar
        id="value"
        v-model="value"
        :disabledDays="disabledDays"
        :minDate="minDate"
        :maxDate="maxDate"
        :showIcon="true"
        autocomplete="off"
        dateFormat="dd/mm/yy"
        :showOnFocus="false"
        placeholder="Range de datas"
        selectionMode="range"
        v-bind="$attrs"
        showButtonBar
        @date-select="verificarDatas"
    />
    <!--    TODO: Add validação-->
    <!--    <small v-if="!value" class="p-error"> {{ requiredText }}</small>-->
    <Toast />
</template>

<script setup>
import { defineProps, defineEmits, computed, watch } from 'vue';
import dayjs from 'dayjs';
import { useToast } from 'primevue/usetoast';
import { getClientBase } from '@/services/http';
import AreaService from '@/services/AreaMedicaService';

const emit = defineEmits(['update:modelValue', 'onGetPeriodoManhaVisible']);

const props = defineProps({
    modelValue: {
        type: Array
    },
    required: {
        type: Boolean,
        default: false
    },
    idCidade: {
        type: Number
    },
    idPrestador: {
        type: Number
    },
    periodo: {
        type: Number
    },
    disabledDays: {
        type: Array,
        default: () => [0, 6]
    },
    minDate: {
        type: Date,
        default: null
    },
    maxDate: {
        type: Date,
        default: null
    }
});

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const diferencaDeDias = computed(() => {
    if (value.value && value.value.length > 1) {
        const dataInicial = dayjs(value.value[0]);
        const dataFinal = dayjs(value.value[1]);
        return dataFinal.diff(dataInicial, 'day');
    }
    return 0;
});

async function verificarDatas() {
    if (!value.value?.[1]) return;
    return (
        (await isRangeDeDatasValido()) &&
        (await isPrestadorFeriasColetivas()) &&
        verificarPeriodoDeAgendamento() &&
        (await isFeriadoMunicipal()) &&
        (await isFeriadoNacional())
    );
}

const toast = useToast();

function verificarPeriodoDeAgendamento() {
    const dataAtual = dayjs();
    const dataAtualSemHoras = dataAtual.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    const dataSelecionada = dayjs(value.value[0]);
    const dataSelecionadaSemHoras = dataSelecionada.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    const periodoManhaAtivo = !(dataSelecionadaSemHoras.isSame(dataAtualSemHoras) && dataAtual.get('hour') > 12);
    emit('onGetPeriodoManhaVisible', periodoManhaAtivo);
    const dataMenorQueAtual = dataSelecionada.isBefore(dataAtual, 'day');
    if (dataMenorQueAtual) {
        toast.add({
            severity: 'warn',
            detail: 'A data inicial não pode ser menor que a data atual',
            life: 3000
        });
        value.value = [];
        return false;
    }
    return true;
}

async function isFeriadoMunicipal() {
    if (!props.idCidade || !value.value[1]) return true;
    const path = `/feriados/municipal/${props.idCidade}`;
    const { data } = await getClientBase().get(path, {
        params: {
            dataInicial: value.value[0],
            dataFinal: value.value[1],
            periodo: props.periodo
        }
    });
    const feriado = data;
    if (feriado.length) {
        toast.add({
            severity: 'warn',
            summary:
                'Por favor selecione outro período de datas, ' +
                dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                ' é feriado ' +
                feriado[0].abrangencia.toLowerCase() +
                ' em ' +
                feriado[0].cidade.cidade +
                '-' +
                feriado[0].cidade.estado +
                '!',
            life: 5000
        });
        value.value = [];
        return false;
    }
    return true;
}

async function isFeriadoNacional() {
    if (!value.value[1] || !props.periodo) return true;
    const path = `/feriados/nacional`;
    const { data } = await getClientBase().get(path, {
        params: {
            dataInicial: value.value[0],
            dataFinal: value.value[1],
            periodo: props.periodo
        }
    });
    const feriado = data;
    if (feriado.length) {
        toast.add({
            severity: 'warn',
            summary:
                'Por favor selecione outro período de datas, ' +
                dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                ' é feriado ' +
                feriado[0].abrangencia.toLowerCase() +
                '!',
            life: 5000
        });
        value.value = [];
        return false;
    }
    return true;
}

async function isPrestadorFeriasColetivas() {
    if (!props.idPrestador || !props.periodo) return true;
    const path = `/ferias/prestador/verificar/${props.idPrestador}`;
    const { data } = await getClientBase().get(path, {
        params: {
            dataInicial: value.value[0],
            dataFinal: value.value[1],
            periodo: props.periodo
        }
    });
    const feriasColetivas = data;
    if (feriasColetivas.length) {
        toast.add({
            severity: 'warn',
            summary:
                'Por favor selecione outro período de datas, o Prestador entrará em Férias no dia ' +
                dayjs(feriasColetivas[0].dataInicial).add(3, 'hour').format('DD/MM/YYYY') +
                ' e retornará no dia ' +
                dayjs(feriasColetivas[0].dataFinal).add(3, 'hour').format('DD/MM/YYYY') +
                '.',
            life: 6000
        });
        value.value = [];
        return false;
    }
    return true;
}

const serviceSLA = new AreaService('configuracoes_sla');

watch(value, async () => {
    await verificarDatas();
});

watch(
    () => props.idCidade,
    async () => {
        await verificarDatas();
    }
);

watch(
    () => props.idPrestador,
    async () => {
        await verificarDatas();
    }
);

watch(
    () => props.periodo,
    async () => {
        await verificarDatas();
    }
);

async function isRangeDeDatasValido() {
    const diffEmDias = diferencaDeDias.value;
    const { data } = await serviceSLA.findAll({ filter: true });

    if (data.length) {
        const limiteDatas = data[0].limiteDatas;
        if (diffEmDias >= limiteDatas) {
            toast.add({
                severity: 'warn',
                summary: 'Por favor selecione outro período de datas, o período da sugestão de datas deve conter no maximo ' + limiteDatas + ' dias.',
                life: 5000
            });
            value.value = [];
            return false;
        }
    }
    return true;
}
</script>
