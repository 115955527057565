<template>
    <div>
        <div style="text-align: center" class="mb-2">
            <a href="javascript:void(0)" @click="$router.replace('/gestao-medica')">Utilizar a tela de agendamentos antiga</a>
        </div>

        <div class="text-800 mb-1">
            <span v-tooltip="'Listagem de agendamentos'" class="text-primary pi pi-info-circle" />
            <span> Agendamentos </span>
            <Button icon="pi pi-info" @click="emit('iniciarTourTela')" class="p-button-outlined ml-2" />
        </div>

        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
            <div class="p-fluid mt-0 w-full md:mt-1 md:w-5">
                <Chips class="step2" v-model="_filter" placeholder="Pesquisar" @add="emit('loadAppointments')" @remove="emit('loadAppointments')" />
            </div>
            <div class="mt-2 ml-2 md:mt-1">
                <Button class="step3" v-tooltip.bottom="'Incluir filtro'" icon="pi pi-filter" @click="carregarFiltros" />
                <Button
                    v-tooltip.bottom="'Gerar Planilha'"
                    :loading="loadingExcel"
                    class="ml-2 p-button-success step4"
                    icon="pi pi-file-excel"
                    @click="gerarPlanilha"
                />
            </div>
            <div class="mt-2 ml-auto md:mt-0">
                <Button
                    v-if="checkPermission('gestamedica_agendamentos:criar')"
                    class="p-button-outlined mr-2 step7"
                    icon="pi pi-list"
                    label="Pré solicitações"
                    @click="openDialogPreSolicitacoes()"
                />
                <Button class="step6" v-if="checkPermission('gestamedica_agendamentos:criar')" label="Novo agendamento" @click="criarAgendamento" />
            </div>
        </div>

        <DialogFiltros
            v-model:filtros-extras="_filtrosExtras"
            :status="_status"
            :exames="exames"
            ref="dialogFiltros"
            @loadAppointments="emit('loadAppointments')"
        />

        <DialogPreSolicitacoes ref="dialogPreSolicitacoes" />

        <DialogCriarAgendamento ref="dialogCriarAgendamento" @load="load" @recarregarTabela="emit('loadAppointments')" />
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import DialogFiltros from './DialogFiltros.vue';
import { getIdsAuthorizedCustomers, getIdsAuthorizedCustomersBranches } from '@/services/auth';
import { getTenant, getToken } from '@/common/storage';
import { getFilterCustomerIds } from '@/services/filters';
import axios from 'axios';
import { useAgendamentosFiltros } from './composables/useAgendamentosFiltros';
import { checkPermission } from '@/common/check-permission';
import DialogPreSolicitacoes from '@/pages/gestao-medica-v3/agendamentos/DialogPreSolicitacoes.vue';
import DialogCriarAgendamento from './criar-agendamento/DialogCriarAgendamento.vue';

const emit = defineEmits(['iniciarTourTela', 'update:filter', 'loadAppointments', 'update:filtrosExtras', 'update:status', 'load']);
const props = defineProps({
    filter: {
        type: Array,
        required: true
    },
    filtrosExtras: {
        type: Object,
        required: true
    },
    sort: {
        type: String,
        required: true
    },
    status: {
        type: Array,
        required: true
    },
    exames: {
        type: Array,
        required: true
    }
});

const { _filter, _filtrosExtras, _status, dialogFiltros, carregarFiltros } = useAgendamentosFiltros(props, emit);

const loadingExcel = ref(false);

async function gerarPlanilha() {
    loadingExcel.value = true;

    const idsAuthorizedCustomers = getIdsAuthorizedCustomers();
    const idsAuthorizedBranches = getIdsAuthorizedCustomersBranches();
    const filteredCustomerIds = getFilterCustomerIds();
    const filtrosExtras = {
        status: props.filtrosExtras?.status?.map((status) => {
            return status.id;
        }),
        tipoExame: props.filtrosExtras?.tipoExame?.map((tipoExame) => {
            return tipoExame.id;
        }),
        datas: props.filtrosExtras?.datas
    };
    axios({
        url: process.env.VUE_APP_API_BASE_URL + `agendamentos/gerar-csv/0`,
        params: {
            filter: props.filter,
            sort: props.sort,
            filtrosExtras
        },
        method: 'GET',
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'x-api-key': getTenant(),
            'filter-customer': JSON.stringify(filteredCustomerIds),
            customers: JSON.stringify(idsAuthorizedCustomers),
            'customer-branches': JSON.stringify(idsAuthorizedBranches)
        }
    })
        .then((res) => {
            const file = window.URL.createObjectURL(new Blob([res.data]));
            const docUrl = document.createElement('a');
            docUrl.href = file;
            docUrl.setAttribute('download', 'relátorio_agendamentos.xlsx');
            document.body.appendChild(docUrl);
            docUrl.click();
        })
        .then(() => {
            loadingExcel.value = false;
        });
}

const dialogPreSolicitacoes = ref(null);
function openDialogPreSolicitacoes() {
    dialogPreSolicitacoes?.value.openDialog();
}

const dialogCriarAgendamento = ref(null);
function criarAgendamento() {
    dialogCriarAgendamento?.value.openDialog();
}

function load() {
    emit('load');
}
</script>
