const TipoExame = {
    ADMISSIONAL: 1,
    PERIODICO: 2,
    RETORNO_AO_TRABALHO: 3,
    DEMISSIONAL: 4,
    MUDANCA_FUNCAO: 5,
    MONITORACAO_PONTUAL: 6,
    ACIDENTE: 7,
    TERCEIROS: 8,
    CONSULTA: 9,
    AVALIACAO_DE_ATESTADO: 10,
    RETORNO_CONSULTA: 11,
    ENFERMAGEM: 12,
    ABONO_ATESTADO: 13,
}

export default TipoExame;
