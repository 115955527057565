import { computed, ref } from 'vue';
import EnumPeriodo from '../../../../../../enums/EnumPeriodo';
import moment from 'moment';
import { obterDiaDaSemanaPorIndice } from '@/enums/EnumDiaDaSemana';

export default function usePeriodo(form, openingHours) {
    const periodoManhaAtivo = ref(true);

    function setPeriodoManhaVisible(visible) {
        periodoManhaAtivo.value = visible;
        if (!periodoManhaAtivo.value && form.value.periodo === 1) {
            form.value.periodo = null;
        }
    }

    const prestadorUsaSoc = computed(() => {
        return form.value.prestador?.systemsAndTechnology?.name === 'SOC';
    });

    const periodos = computed(() => {
        {
            const periodo = [
                { label: 'Manhã', value: EnumPeriodo.MANHA, visible: periodoManhaAtivo.value && !isNaoAtendePeriodoManha.value },
                { label: 'Tarde', value: EnumPeriodo.TARDE, visible: !isNaoAtendePeriodoTarde.value },
                {
                    label: 'Ambos',
                    value: EnumPeriodo.AMBOS,
                    visible: periodoManhaAtivo.value && !prestadorUsaSoc.value && !isNaoAtendePeriodoManha.value && !isNaoAtendePeriodoTarde.value
                }
            ];
            return periodo.filter((p) => p.visible === true);
        }
    });

    const isNaoAtendePeriodoTarde = ref(false);
    const isNaoAtendePeriodoManha = ref(false);

    function validarDisponibilidadePeriodo() {
        if (!form.value.prestador || !form.value?.intervaloSugerido?.[0] || !form.value?.intervaloSugerido?.[1]) {
            return;
        }

        let dataInicial = moment(form.value.intervaloSugerido[0]);
        const dataFinal = moment(form.value.intervaloSugerido[1]);
        const totalDias = dataFinal.diff(dataInicial, 'days') + 1;
        const dias = Array.from({ length: totalDias }, (_, index) => moment(dataInicial).add(index, 'days'));

        const atendimentosMatutinos = dias.map((dia) => {
            const diaDaSemana = obterDiaDaSemanaPorIndice(dia.day());
            const horarioDeAtendimento = openingHours.value.find((p) => p.weekDay === diaDaSemana);
            return horarioDeAtendimento?.atendimentoMatutino;
        });

        const atendimentosVespertinos = dias.map((dia) => {
            const diaDaSemana = obterDiaDaSemanaPorIndice(dia.day());
            const horarioDeAtendimento = openingHours.value.find((p) => p.weekDay === diaDaSemana);
            return horarioDeAtendimento?.atendimentoVespertino;
        });

        isNaoAtendePeriodoManha.value = atendimentosMatutinos.every((valor) => !valor);
        isNaoAtendePeriodoTarde.value = atendimentosVespertinos.every((valor) => !valor);
    }

    return {
        setPeriodoManhaVisible,
        prestadorUsaSoc,
        periodos,
        validarDisponibilidadePeriodo
    };
}
