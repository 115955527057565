<template>
    <div>
        <div v-if="loadingInformacoesSoc" class="flex align-content-center justify-content-center flex-wrap gap-4 mt-3" style="height: 100%">
            <i class="pi pi-spin pi-spinner text-primary" style="font-size: 5rem"></i>
            <span class="flex align-items-center justify-content-center text-2xl" style="min-width: 100%">Carregando informações...</span>
        </div>
        <div
            v-if="!loadingInformacoesSoc && erroSincronizacaoSoc && !showMessageCobrancaExamesEmDia"
            class="flex align-content-center justify-content-center flex-wrap gap-4 mt-3"
        >
            <i class="pi pi-times-circle text-pink-600" style="font-size: 8rem"></i>
            <span class="flex align-items-center justify-content-center text-2xl" style="min-width: 100%"
                >Ocorreu um problema ao tentar sincronizar dados com o SOC!</span
            >
            <Button label="Tentar novamente" @click="start" />
        </div>
        <div v-if="!loadingInformacoesSoc && !erroSincronizacaoSoc && showMessageCobrancaExamesEmDia">
            <Message @close="showMessageCobrancaExamesEmDia = false">
                Em caso de aceite de um exame em dia está sujeito a <strong>cobrança</strong>.</Message
            >
        </div>

        <DataTable v-if="!loadingInformacoesSoc && !erroSincronizacaoSoc" :value="form.funcionarios" responsiveLayout="scroll" breakpoint="640px">
            <Column field="name" header="Nome" style="width: 25%; min-width: 185px" />
            <Column v-if="exameClinicoObrigatorio" header="Procedimentos em dia" style="width: 25%; min-width: 185px">
                <template #body="{ index, data }">
                    <Button
                        :disabled="!temProcedimentosEmDia(data)"
                        class="p-button-link"
                        label="Ver procedimentos"
                        @click="openOverlayProcedimentosEmDia($event, form.funcionarios[index])"
                    />
                </template>
            </Column>
            <Column header="Procedimentos a realizar" style="width: 25%; min-width: 185px">
                <template v-if="exameClinicoObrigatorio" #body="{ index }">
                    <Button
                        class="p-button-link"
                        label="Ver procedimentos"
                        @click="openOverlayProcedimentosRealizar($event, form.funcionarios[index])"
                    />
                </template>
                <template v-else #body="{ index }">
                    <MultiSelectExame
                        class="w-full"
                        v-model="form.funcionarios[index].procedimentos"
                        :class="{
                            'p-invalid': v$.funcionariosItens.$dirty && v$.funcionariosItens.$each.$response.$errors[index]?.procedimentos?.length
                        }"
                        :habilitaDialogObservacao="props.formData.tipoExame?.id === TipoExame.CONSULTA"
                    />
                    <small
                        v-if="v$.funcionariosItens.$dirty && v$.funcionariosItens.$each.$response.$errors[index]?.procedimentos?.length"
                        class="p-error"
                    >
                        {{ v$.funcionariosItens.$each.$response.$errors[index].procedimentos[0].$message }}
                    </small>
                </template>
            </Column>
            <Column v-if="exameClinicoObrigatorio" style="width: 25%; min-width: 130px">
                <template #body="{ index }">
                    <Button
                        v-if="mostrarLinkBaixarAso(form.funcionarios[index])"
                        class="p-button-link"
                        label="Baixar ASO"
                        icon="pi pi-download"
                        @click="openDialogAnexo(form.funcionarios[index])"
                    />
                </template>
            </Column>
            <Column v-if="haFuncionariosSemRico" header="Questionário de riscos" style="width: 25%; min-width: 230px">
                <template #body="{ data: funcionario, index }">
                    <FormSolicitacoesRisco
                        class="p-fluid"
                        v-if="!funcionario.possuiRisco && !funcionario?.customer.desabilitarQuestionarioRisco"
                        :ref="(el) => (formSolicitacoesRisco[index] = el)"
                        :funcionarioId="funcionario.id"
                        @update="setInfoRisco"
                    />
                </template>
            </Column>
            <Column header="Detalhes da consulta" style="width: 25%; min-width: 230px" v-if="props.formData.tipoExame?.id === TipoExame.CONSULTA">
                <template #body="{ data: funcionario, index }">
                    <FormSolicitacoesConsulta
                        class="mt-1"
                        :ref="(el) => (formSolicitacoesConsulta[index] = el)"
                        :funcionarioId="funcionario.id"
                        @update="setInfoConsulta"
                    />
                </template>
            </Column>
        </DataTable>
        <OverlayProcedimentosEmDia
            ref="overlayProcedimentosEmDia"
            @onChangeRefazerExameEmDia="onChangeRefazerExameEmDia"
            :isDemissional="isDemissional"
        />
        <OverlayProcedimentosRealizar ref="overlayProcedimentosRealizar" />
        <AppAnexoDialog
            v-if="showAnexoDialog"
            v-model:visible="showAnexoDialog"
            :enableImport="false"
            :enableRemove="false"
            title="ASOs para o Funcionário"
            tipo="ASO"
            origem="FUNCIONARIO_ASO"
            v-model:origemId="anexoFuncionarioId"
            @onClickCloseAnexo="showAnexoDialog = false"
        />
    </div>
</template>

<script setup>
import { computed, defineEmits, defineExpose, defineProps, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import OverlayProcedimentosEmDia from './components/OverlayProcedimentosEmDia.vue';
import OverlayProcedimentosRealizar from './components/OverlayProcedimentosRealizar.vue';
import { showWarning } from '@/utils/Toast';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';
import BaseService from '../../../../../services/BaseService';
import { verificarExameOcupacional } from '@/utils/VerificarExameOcupacional';
import MultiSelectExame from '../../../../exames/components/MultiSelectExame.vue';
import { helpers } from '@vuelidate/validators';
import TipoExame from '../../../../../enums/TipoExame';
import FormSolicitacoesRisco from './components/FormSolicitacoesRisco.vue';
import FormSolicitacoesConsulta from './components/FormSolicitacoesConsulta.vue';

const emit = defineEmits(['update:formData', 'desabilitarBotaoAvancar']);
const serviceRiscos = new BaseService('/customer/employees/riscos-soc');
const toast = useToast();
const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});

const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

const funcionariosItens = computed(() => form.value.funcionarios || []);

const overlayProcedimentosEmDia = ref(null);
const overlayProcedimentosRealizar = ref(null);
const loadingInformacoesSoc = ref(false);
const erroSincronizacaoSoc = ref(false);
const showMessageCobrancaExamesEmDia = ref(false);
const exameClinicoObrigatorio = ref(false);
const showAnexoDialog = ref(false);
const anexoFuncionarioId = ref(null);

watch(erroSincronizacaoSoc, (value) => {
    emit('desabilitarBotaoAvancar', value);
});

watch(loadingInformacoesSoc, (value) => {
    emit('desabilitarBotaoAvancar', value);
});

function temProcedimentosEmDia(funcionario) {
    return funcionario.exames?.some((p) => p.dataRefazerExame);
}

const isDemissional = computed(() => {
    return form.value?.tipoExame?.id === TipoExame.DEMISSIONAL;
});

function openOverlayProcedimentosEmDia(event, funcionario) {
    overlayProcedimentosEmDia?.value.openOverlay(event, funcionario, exameClinicoObrigatorio.value);
}

function openOverlayProcedimentosRealizar(event, funcionario) {
    overlayProcedimentosRealizar?.value.openOverlay(event, funcionario, exameClinicoObrigatorio.value);
}

function openDialogAnexo(funcionario) {
    anexoFuncionarioId.value = funcionario.id;
    showAnexoDialog.value = true;
}

function mostrarLinkBaixarAso(funcionario) {
    return funcionario?.exames?.some((p) => p.dataRefazerExame);
}

const rules = {
    funcionariosItens: {
        $each: helpers.forEach({
            procedimentos: {
                required: helpers.withMessage('O campo precisa ser informado corretamente', (value) => value.length)
            }
        })
    }
};

const v$ = useVuelidate(rules, { funcionariosItens });

async function start() {
    await buscarRiscosEExamesDosFuncionarios();
    onChangeRefazerExameEmDia();
}

async function buscarRiscosEExamesDosFuncionarios() {
    if (!form.value?.tipoExame) return;
    if (!form.value?.funcionarios?.length) return;

    loadingInformacoesSoc.value = true;
    const funcionariosAgrupados = agruparFuncionariosConformeLimiteRequisicaoSoc(form.value.funcionarios);

    for (const funcionarios of funcionariosAgrupados) {
        await Promise.all(
            funcionarios.map(async (funcionario) => {
                if (form.value.funcionarios?.some((element) => element.id === funcionario.id)) {
                    await buscarRiscosDoFuncionario(funcionario);
                    await buscarExamesDoFuncionario(funcionario);
                }
            })
        );
    }

    loadingInformacoesSoc.value = false;
}

function agruparFuncionariosConformeLimiteRequisicaoSoc(funcionarios) {
    const limiteSolicitacoesSimultaneasSOC = 5;
    return funcionarios.reduce((grupos, funcionario) => {
        if (grupos.at(-1)?.length < limiteSolicitacoesSimultaneasSOC - 1) {
            grupos.at(-1).push(funcionario);
        } else {
            grupos.push([funcionario]);
        }

        return grupos;
    }, []);
}

async function buscarRiscosDoFuncionario(funcionario) {
    try {
        erroSincronizacaoSoc.value = false;
        const { data } = await serviceRiscos.findById(funcionario.id);
        funcionario.riscos = data;
        funcionario.possuiRisco = data.length !== 0;
    } catch (error) {
        erroSincronizacaoSoc.value = true;
    }
}

async function buscarExamesDoFuncionario(funcionario) {
    const { id, customerId } = funcionario;
    const tipoExameId = form.value?.tipoExame?.id;
    erroSincronizacaoSoc.value = false;
    try {
        const { data } = await getClientBase().get(
            `/importacao/procedimentos-soc/funcionario/${id}/customer/${customerId}/tipo-exame/${tipoExameId}`
        );
        if (!data) return;

        exameClinicoObrigatorio.value = verificarExameOcupacional(tipoExameId);

        data.procedimentos.forEach((el) => {
            const exameEmDiaEncontrado = data.examesEmDiaList.find((item) => item.idExame === el.procedimento.id);
            el.dataRefazerExame = exameEmDiaEncontrado?.dataRefazerExame;
            el.aceitar = !!(el.procedimento.clinical && exameClinicoObrigatorio) || !exameEmDiaEncontrado;
        });

        atribuirExamesAoFuncionario(data, funcionario);
    } catch (error) {
        erroSincronizacaoSoc.value = true;
    }
}

function atribuirExamesAoFuncionario(data, funcionario) {
    if (!exameClinicoObrigatorio.value) {
        funcionario.exames = [];
        funcionario.procedimentos = [];

        v$.value.funcionariosItens.$reset();
        return;
    }

    if (funcionario.exames && funcionario.procedimentos) {
        data.procedimentos.forEach((el) => {
            if (funcionario.exames.some((exameMarcado) => exameMarcado.procedimento.id === el.procedimento.id && exameMarcado.aceitar)) {
                el.aceitar = true;
            }
        });
    } else {
        funcionario.exames = data.procedimentos;
        funcionario.procedimentos = data.procedimentos.filter((item) => item.aceitar).map((el) => ({ ...el.procedimento }));
        form.value.examesEmDia ??= data.examesEmDia;
    }
}

function onChangeRefazerExameEmDia() {
    showMessageCobrancaExamesEmDia.value = false;
    let exameEmDiaRefazerEncontrado = false;

    form.value?.funcionarios.forEach((funcionario) => {
        funcionario.procedimentos = funcionario.exames?.filter((p) => p.aceitar).map((el) => ({ ...el.procedimento }));
        const exameEmDiaRefazer = verificarExameEmDiaRefazer(funcionario);

        if (exameEmDiaRefazer) {
            exameEmDiaRefazerEncontrado = true;
        }
    });

    showMessageCobrancaExamesEmDia.value = exameEmDiaRefazerEncontrado;
}

function verificarExameEmDiaRefazer(funcionario) {
    return (
        funcionario.exames?.some((item) => item.aceitar && !item.procedimento.clinical && item.dataRefazerExame) ||
        (funcionario.exames?.length === 1 && funcionario.exames.find((p) => p.aceitar && p.procedimento.clinical && p.dataRefazerExame))
    );
}

const formSolicitacoesRisco = ref([]);

function setInfoRisco(data) {
    const funcionario = form.value.funcionarios.find((element) => element.id === data.funcionarioId);
    funcionario.questionarioRisco = data.questionarioRisco;
    funcionario.questionarioHierarquiaId = funcionario.customerHierarchyId;
}

const formSolicitacoesConsulta = ref([]);

function setInfoConsulta(data) {
    const funcionario = form.value.funcionarios.find((element) => element.id === data.funcionarioId);
    funcionario.detalheConsulta = data.detalheConsulta;
}

async function validarCamposRiscoEConsulta() {
    let valido = true;
    for (let i = 0; i < form.value.funcionarios.length; i++) {
        if (formSolicitacoesRisco.value?.length && formSolicitacoesRisco.value?.[i]) {
            const result = await formSolicitacoesRisco.value?.[i]?.validate();
            valido = valido && result;
        }
        if (formSolicitacoesConsulta.value?.length && formSolicitacoesConsulta.value?.[i]) {
            const result = await formSolicitacoesConsulta.value?.[i]?.validate();
            valido = valido && result;
        }
    }
    return valido;
}

const haFuncionariosSemRico = computed(() => {
    return form.value.funcionarios?.some((funcionario) => !funcionario.possuiRisco);
});

defineExpose({
    async validarFormulario() {
        if (erroSincronizacaoSoc.value) {
            showWarning(toast, 'Ocorreu um problema ao tentar sincronizar dados com o SOC!');
            return;
        }
        const valido = await validarCamposRiscoEConsulta();
        if (!valido) return false;
        return v$.value.$validate();
    },
    start
});
</script>
