import { RouterView } from 'vue-router';

const AgendamentoIndex = () => import('../pages/gestao_medica/agendamentos/index/Index.vue');
const AgendamentoFormSolicitacao = () => import('../pages/gestao_medica/agendamentos/form/FormSolicitacao.vue');
const AgendamentoFormPrestador = () => import('../pages/gestao_medica/agendamentos/form/FormPrestador.vue');
const AgendamentoFormProcedimentos = () => import('../pages/gestao_medica/agendamentos/form/FormProcedimentos.vue');
const AgendamentoFormSolicitacoes = () => import('../pages/gestao_medica/agendamentos/form/FormSolicitacoes.vue');
const AgendamentoFormExames = () => import('../pages/gestao_medica/agendamentos/form/FormExames.vue');
const AgendamentosFormExamesProcedimento = () => import('../pages/gestao_medica/agendamentos/form/FormExamesProcedimentos.vue')

const gestaoMedica = {
    path: '/gestao-medica',
    redirect: '/gestao-medica/agendamentos',
    component: RouterView,
    children: [
        {
            path: 'agendamentos',
            name: 'agendamentos',
            component: AgendamentoIndex,
            children: [
                {
                    path: 'solicitacao',
                    name: 'agendamentos.solicitacao',
                    component: AgendamentoFormSolicitacao
                },
                {
                    path: 'prestador',
                    name: 'agendamentos.prestador',
                    component: AgendamentoFormPrestador
                },
                {
                    path: 'exames-procedimentos',
                    name: 'agendamentos.exames.procedimentos',
                    component: AgendamentosFormExamesProcedimento
                },
                {
                    path: 'procedimentos',
                    name: 'agendamentos.procedimentos',
                    component: AgendamentoFormProcedimentos
                },
                {
                    path: 'solicitacoes',
                    name: 'agendamentos.solicitacoes',
                    component: AgendamentoFormSolicitacoes
                },
                {
                    path: 'exames',
                    name: 'agendamentos.exames',
                    component: AgendamentoFormExames
                }
            ]
        }
    ]
};

export default gestaoMedica;
