<template>
    <div class="grid border-2 border-200 border-round-md my-4 mx-0 p-3">
        <DialogTipoExameAutoagendamento :modelValue="tipoDeExameNaoPermitido" @voltarPasso="voltarPasso" />
        <DialogAgendamentoNaoPermitido
            :modelValue="funcionarioSemRiscoNaoPermitido"
            @voltarPasso="voltarPasso"
            titulo="Funcionário sem risco não permitido"
            mensagem="Funcionário sem risco não pode ser agendado através do autoagendamento, se deseja realizar agendamento deste tipo, por favor continue através do agendamento manual."
        />
        <DialogMudancaRiscos
            ref="dialogMudancaRiscosAutoagendamento"
            @onConfirmacaoCobranca="mudancaFuncaoOnConfirmacaoCobranca"
            @onCancelar="mudancaFuncaoOnRejeicaoCobranca"
        />
        <div :class="form?.tipoExame?.id === TipoExame.DEMISSIONAL ? 'col-6 lg:col-3' : 'col-12 lg:col-4'">
            <label for="funcionario"> Nome do funcionário <span class="p-error">*</span> </label>
            <AppOnePageDropdown
                id="funcionario"
                v-model="funcionario"
                :class="{ 'p-invalid': $v?.funcionario.$error }"
                :itemSize="20"
                :loading="loading"
                :service="$serviceFuncionario"
                autoLoad
                class="w-full mt-2"
                optionLabel="name"
                placeholder="Selecionar"
                @change="buscarRiscosDoFuncionario"
            />
            <small v-if="$v?.funcionario.$error" class="p-error">
                {{ $v?.funcionario.$errors[0].$message }}
            </small>
        </div>
        <div :class="form?.tipoExame?.id === TipoExame.DEMISSIONAL ? 'col-6 lg:col-3' : 'col-12 lg:col-4'">
            <label for="tipoExame"> Tipo de exame <span class="p-error">*</span> </label>
            <Dropdown
                id="tipoExame"
                :class="{ 'p-invalid': $v?.form.tipoExame.$error }"
                :disabled="!funcionario"
                :loading="loading"
                :modelValue="form.tipoExame"
                :options="itensTipoDeExame"
                class="w-full mt-2"
                optionLabel="descricao"
                placeholder="Selecionar"
                @change="validarSeAutoAtendimentoEPermitido"
            />
            <small v-if="$v?.form.tipoExame.$error" class="p-error">
                {{ $v?.form.tipoExame.$errors[0].$message }}
            </small>
        </div>
        <div
            :class="form?.tipoExame?.id === TipoExame.DEMISSIONAL ? 'col-6 lg:col-3' : 'col-12 lg:col-4'"
            v-if="form?.tipoExame?.id === TipoExame.DEMISSIONAL"
        >
            <label for="dataHomologacao"> Data de demissão <span class="p-error">*</span> </label>
            <Calendar
                id="dataHomologacao"
                v-model="dataHomologacao"
                dateappointmentSelectedat="dd/mm/yy"
                :class="{ 'p-invalid': $v?.form.dataHomologacao.$error || $v?.form.dataHomologacao.$error }"
                selectionMode="single"
                :showIcon="true"
                :minDate="moment().toDate()"
                class="w-full mt-2"
            />
            <small v-if="$v?.form.dataHomologacao.$error" class="p-error">
                {{ $v?.form.dataHomologacao.$errors[0].$message }}
            </small>
        </div>
        <div :class="form?.tipoExame?.id === TipoExame.DEMISSIONAL ? 'col-6 lg:col-3' : 'col-12 lg:col-4'">
            <label for="intervaloSugerido"> Sugerir intervalo de data <span class="p-error">*</span> </label>
            <AppInputCalendarioRanged
                id="intervaloSugerido"
                v-model="intervaloSugerido"
                :class="{ 'p-invalid': $v?.form.dataSugestaoInicial.$error || $v?.form.dataSugestaoFinal.$error }"
                :disabled="loading"
                :limiteDatas="10"
                :minDate="moment().add(1, 'day').toDate()"
                class="w-full mt-2"
                required
            />
            <small v-if="$v?.form.dataSugestaoInicial.$error" class="p-error">
                {{ $v?.form.dataSugestaoInicial.$errors[0].$message }}
            </small>
            <small v-if="$v?.form.dataSugestaoFinal.$error" class="p-error">
                {{ $v?.form.dataSugestaoFinal.$errors[0].$message }}
            </small>
        </div>
        <div v-if="exibirQuestionarioDeRisco" class="col-12">
            <label for="questionarioRisco"> Questionário de riscos <span class="p-error">*</span> </label>
            <MultiSelect
                id="questionarioRisco"
                v-model="questionarioRisco"
                :class="{ 'p-invalid': $v?.questionarioRisco.$error }"
                :options="itensQuestionarioDeRisco"
                :showToggleAll="false"
                class="w-full mt-2 py-1"
                dataKey="id"
                optionLabel="pergunta"
                placeholder="Preencha o questionário"
                @change="definirRiscos($event)"
            />
            <small v-if="$v?.questionarioRisco.$error" class="p-error">
                {{ $v?.questionarioRisco.$errors[0].$message }}
            </small>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, defineProps, defineEmits, defineExpose, computed, inject } from 'vue';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { getClientBase } from '@/services/http';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import AreaMedicaService from '@/services/AreaMedicaService';
import BaseService from '@/services/BaseService';
import EnumPeriodo from '@/enums/EnumPeriodo';
import EnumQuemAgendaAvisa from '@/enums/QuemAgendaAvisa';
import DialogTipoExameAutoagendamento from '../components/DialogTipoExameAutoagendamento.vue';
import TipoExame from '@/enums/TipoExame';
import moment from 'moment';
import DialogMudancaRiscos from './components/DialogMudancaRiscos.vue';
import DialogAgendamentoNaoPermitido from '../components/DialogAgendamentoNaoPermitido.vue';

const emit = defineEmits(['update', 'voltarPasso']);
const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});

const $serviceFuncionario = new BaseService(`/customer/employees/funcionarios-por-situacao/ATIVO,AFASTADO,PENDENTE`);
const $serviceQuestionarioDeRisco = new BaseService('/questionario-risco');
const $serviceRiscos = new BaseService('/customer/employees/riscos-soc');
const $serviceTiposExames = new AreaMedicaService('/tipo_exames');

const exibirQuestionarioDeRisco = ref(false);
const itensTipoDeExame = ref([]);
const itensQuestionarioDeRisco = ref([]);
const loading = ref(false);

const tipoDeExameNaoPermitido = ref(false);
const funcionarioSemRiscoNaoPermitido = ref(false);

const toast = useToast();
const dialogMudancaRiscosAutoagendamento = ref(null);

const form = computed({
    get() {
        return props.formData;
    },
    set(novoValor) {
        emit('update:formData', novoValor);
    }
});
const funcionario = computed({
    get() {
        return form.value.funcionarios?.[0];
    },
    set(novoValor) {
        if (novoValor?.questionarioRisco) {
            novoValor.questionarioRisco = null;
        }

        form.value.funcionarios = [novoValor];
        form.value.pcd = novoValor?.pcd;
        form.value.cliente = novoValor?.customerId;
        form.value.emailFuncionario = novoValor?.email;
        form.value.telefoneFuncionario = novoValor?.phone;
        form.value.nomeFuncionario = novoValor?.name;
    }
});
const intervaloSugerido = computed({
    get() {
        return [form.value.dataSugestaoInicial, form.value.dataSugestaoFinal].filter(Boolean);
    },
    set(novoValor) {
        form.value.dataSugestaoInicial = novoValor?.[0];
        form.value.dataSugestaoFinal = novoValor?.[1];
    }
});
const questionarioRisco = computed({
    get() {
        return funcionario.value?.questionarioRisco;
    },
    set(novoValor) {
        funcionario.value.questionarioRisco = novoValor;
    }
});
const dataHomologacao = computed({
    get() {
        return form.value?.dataHomologacao;
    },
    set(novoValor) {
        form.value.dataHomologacao = novoValor;
    }
});
const regrasDeValidacaoDosCampos = computed(() => ({
    form: {
        tipoExame: {
            required: helpers.withMessage('Campo obrigatório', required)
        },
        dataSugestaoInicial: {
            required: helpers.withMessage('Campo obrigatório', required)
        },
        dataSugestaoFinal: {
            requiredIf: helpers.withMessage('A data de sugestão final é obrigatória', requiredIf(form.value.dataSugestaoInicial))
        },
        dataHomologacao: {
            requiredIf: helpers.withMessage(
                'A data de demissão é obrigatória',
                requiredIf(() => form.value.tipoExame?.id === TipoExame.DEMISSIONAL)
            )
        }
    },
    funcionario: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    questionarioRisco: {
        requiredIf: helpers.withMessage('Campo obrigatório', requiredIf(exibirQuestionarioDeRisco))
    }
}));
const $v = useVuelidate(regrasDeValidacaoDosCampos, {
    form,
    funcionario,
    questionarioRisco,
    dataHomologacao
});

onMounted(async () => {
    const { data: tiposDeExame } = await $serviceTiposExames.findAll({});
    itensTipoDeExame.value = tiposDeExame;

    const { data: perguntas } = await $serviceQuestionarioDeRisco.findAll({});
    itensQuestionarioDeRisco.value = perguntas;

    setarAutoAgendamento();
});

async function buscarExamesDoFuncionario() {
    loading.value = true;
    mudancaFuncaoVerificar(funcionario.value);
    if (!funcionario.value) return;
    try {
        const { id, customerId } = funcionario.value;
        const tipoExameId = form.value.tipoExame.id;
        const { data } = await getClientBase().get(
            `/importacao/procedimentos-soc/funcionario/${id}/customer/${customerId}/tipo-exame/${tipoExameId}`
        );

        if (!data) return;

        data.procedimentos.forEach((el) => {
            const exame = data.examesEmDiaList.find((item) => item.idExame === el.procedimento.id);
            el.dataRefazerExame = exame?.dataRefazerExame;
            el.aceitar = !exame;
        });

        form.value.procedimentos = data.procedimentos.map((item) => item.procedimento);
    } catch (error) {
        toast.add({
            severity: 'warn',
            summary: 'Não foi possível consultar os Exames no SOC',
            detail: error?.message,
            life: 5000
        });
    }
    loading.value = false;
}
async function buscarRiscosDoFuncionario(funcionario) {
    form.value.tipoExame = null;
    loading.value = true;
    try {
        if (!funcionario.value) return;

        const { data: riscos } = await $serviceRiscos.findById(funcionario.value.id);
        funcionario.value.possuiRisco = !!riscos.length;
        funcionario.value.riscos = riscos;

        if (!riscos?.length) {
            form.value.tipoExame = null;
            funcionarioSemRiscoNaoPermitido.value = true;
        }
    } catch (error) {
        toast.add({
            severity: 'warn',
            summary: 'Não foi possível consultar os Riscos no SOC',
            detail: error?.message,
            life: 5000
        });
    }
    loading.value = false;
}
async function setarAutoAgendamento() {
    form.value.quemAvisa = EnumQuemAgendaAvisa.SOLICITANTE;
    form.value.quemAgenda = EnumQuemAgendaAvisa.USUARIO_ESCOLHE;
    form.value.periodo = EnumPeriodo.AMBOS;
    form.value.resideForaDoPais = false;
    form.value.status = { id: 1 };
    form.value.autoagendamento = true;
}
function definirRiscos() {
    const hierarquiaId = funcionario.value.customerHierarchyId;
    form.value.questionarioHierarquiaId = hierarquiaId;
}

function validarSeAutoAtendimentoEPermitido(tipoExame) {
    form.value.dataHomologacao = null;
    const examesNaoPermitidos = [TipoExame.RETORNO_AO_TRABALHO, TipoExame.CONSULTA, TipoExame.TERCEIROS, TipoExame.AVALIACAO_DE_ATESTADO];
    if (examesNaoPermitidos.includes(tipoExame.value.id)) {
        form.value.tipoExame = null;
        tipoDeExameNaoPermitido.value = true;
    } else {
        form.value.tipoExame = tipoExame.value;
        buscarExamesDoFuncionario();
    }
}
function voltarPasso() {
    emit('voltarPasso');
    limparFormulario();
}

function limparFormulario() {
    tipoDeExameNaoPermitido.value = false;
    funcionarioSemRiscoNaoPermitido.value = false;
    form.value.autoagendamento = false;
    form.value.funcionarios = [];
    form.value.tipoExame = null;
    intervaloSugerido.value = null;
}

const setHouveramFuncionariosComRisco = inject('setHouveramFuncionariosComRisco');

function mudancaFuncaoVerificar(funcionario) {
    if (!form.value.tipoExame || form.value.tipoExame?.id !== TipoExame.MUDANCA_FUNCAO) {
        return;
    }

    if (!funcionario?.mudouRisco && funcionario) {
        dialogMudancaRiscosAutoagendamento?.value.openDialog(funcionario);
    }
}

function mudancaFuncaoOnConfirmacaoCobranca(idFuncionarioSemMudancaDeRisco) {
    if (funcionario.value.id === idFuncionarioSemMudancaDeRisco) {
        funcionario.value.asoEmDia = true;
    }
}

function mudancaFuncaoOnRejeicaoCobranca() {
    form.value.funcionarios = [];
    form.value.tipoExame = null;
    form.value.cliente = null;
    setHouveramFuncionariosComRisco?.();
}

defineExpose({
    validarFormulario: async () => await $v?.value.$validate()
});
</script>
