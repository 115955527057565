<template>
    <OverlayPanel ref="overlayPanel" showCloseIcon :style="{width: '30vw'}">
        <DataTable :value="procedimentos">
            <Column field="name" header="Procedimento" />
        </DataTable>
    </OverlayPanel>
</template>

<script setup>
import { ref, defineExpose } from 'vue';

const overlayPanel = ref(null);
const procedimentos = ref([]);
const toggle = (event) => overlayPanel?.value.toggle(event);

function openOverlay(event, funcionario) {
    procedimentos.value = funcionario.procedimentos;
    toggle(event);
}

defineExpose({
    openOverlay
});
</script>

<style></style>
